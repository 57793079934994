import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "../../components/UI/Button/Button/Button";
import SettingsInput from "../../components/UI/SettingsInput/SettingsInput";
import FieldInstructions from "../../components/UI/SettingsInput/FieldInstructions/FieldInstructions";
import styles from "./EditLeadForm.module.css";
import PropTypes from "prop-types";

class EditLeadForm extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    leadForm: PropTypes.object,
    formInputs: PropTypes.objectOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    this.state = {
      // FIXME Remove this, make SettingsInput or whatever work properly instead.
      showInstructions: false,
    };
  }

  createOnChangeHandler(propName, formInputId = null) {
    return e => {
      let value = null;
      // ReactQuill returns a string instead of an event, so we need to check for that.
      if (typeof e === "string") {
        value = e;
      } else if (e.target.type === "checkbox") {
        value = e.target.checked;
      } else {
        value = e.target.value;
      }

      this.props.onChange(propName, value, formInputId);
    };
  }

  // FIXME Remove this and make SettingsInput (or field instructions or whatever) work
  // properly instead.
  onMouseOut = () => {
    this.setState({ showInstructions: false });
  };

  // FIXME Remove this and make SettingsInput (or field instructions or whatever) work
  // properly instead.
  onMouseOver = () => {
    this.setState({ showInstructions: true });
  };

  render() {
    const { leadForm, formInputs } = this.props;
    if (!leadForm) {
      return null;
    }
    const quillModules = {
      toolbar: ["bold", "italic", "underline", "link"],
    };

    // Copy-pasted from settings.
    let leadFormInputs = leadForm.formInputs
      .filter(formInputId => formInputs[formInputId].inputType !== "checkbox")
      .map(formInputId => {
        const formInput = formInputs[formInputId];
        return (
          <div key={formInput.id + "fragment"}>
            {formInput.inputType === "text" ? (
              <SettingsInput
                label={formInput.uiLabel}
                key={formInput.id}
                htmlName={formInput.inputName}
                value={formInput.inputLabel}
                changeState={this.createOnChangeHandler(
                  "inputLabel",
                  formInput.id
                )}
                type="text"
                changeVisibility={this.createOnChangeHandler(
                  "isVisible",
                  formInput.id
                )}
                changeMandatory={this.createOnChangeHandler(
                  "isRequired",
                  formInput.id
                )}
                isChecked={formInput.isVisible}
                isRequired={formInput.isRequired}
                errorMessage={formInput.errorMessage}
                errorMessageName={formInput.errorMessage}
                errorMessageHandler={this.createOnChangeHandler(
                  "errorMessage",
                  formInput.id
                )}
                isLeadForm
                helpText={formInput.instructions}
                showInstructions={this.state.showInstructions}
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
              />
            ) : (
              <SettingsInput
                label={formInput.uiLabel}
                key={formInput.id}
                htmlName={formInput.inputName}
                value={formInput.inputLabel}
                changeState={this.createOnChangeHandler(
                  "textFields",
                  formInput.id
                )}
                type="text"
                isChecked={formInput.isVisible}
                isRequired={formInput.isRequired}
                errorMessage={formInput.errorMessage}
                errorMessageName={formInput.errorMessage}
                errorMessageHandler={this.createOnChangeHandler(
                  "errorMessage",
                  formInput.id
                )}
                isLeadForm
                helpText={formInput.instructions}
                showInstructions={this.state.showInstructions}
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
              />
            )}
          </div>
        );
      });

    // Copy-pasted from settings.
    let leadFormInputsRichTextEditor = leadForm.formInputs
      .filter(formInputId => formInputs[formInputId].inputType === "checkbox")
      .map(formInputId => {
        const formInput = formInputs[formInputId];
        return (
          <div key={formInput.id} className={styles.Row}>
            <SettingsInput
              key={formInput.id}
              htmlName={formInput.inputName}
              value={formInput.inputLabel}
              changeState={this.createOnChangeHandler(
                "textFields",
                formInput.id
              )}
              type="editor"
              changeVisibility={this.createOnChangeHandler(
                "isVisible",
                formInput.id
              )}
              changeMandatory={this.createOnChangeHandler(
                "isRequired",
                formInput.id
              )}
              isChecked={formInput.isVisible}
              isRequired={formInput.isRequired}
            />
            <ReactQuill
              className={styles.Editor}
              modules={this.modules}
              value={formInput.inputLabel}
              onChange={this.createOnChangeHandler("inputLabel", formInput.id)}
            />
            <SettingsInput
              type="editorInstructions"
              helpText={formInput.instructions}
              showInstructions={this.state.showInstructions}
              onMouseEnter={this.onMouseOver}
              onMouseLeave={this.onMouseOut}
            />
          </div>
        );
      });

    // FIXME: Copy-pasted from EditSettings so I can get some progress on this thing.
    // onMouseOver/onMouseOut shouldn't be here, but in SettingsInput instead.
    // Do something about the wrappers (probably don't need four of them).
    return (
      <div className={styles.Wrapper}>
        <div className={styles.SettingsWrapper}>
          <div className={styles.ViewWrapper}>
            <div className={styles.LeadFormWrapper}>
              <div className={`${styles.Group} ${styles.LeadForm}`}>
                {/* Form title */}
                {/* TODO Not actually used anywhere. */}
                <SettingsInput
                  label="Form name"
                  helpText="Form name"
                  type="text"
                  htmlName="formTitle"
                  changeState={this.createOnChangeHandler("title")}
                  value={leadForm.title}
                  onMouseEnter={this.onMouseOver}
                  onMouseLeave={this.onMouseOut}
                  showInstructions={this.state.showInstructions}
                />

                {/* Form content */}
                <label className={styles.Label}>Form content</label>
                {/* FIXME: Oh boy. Another wrapper. */}
                <div className={styles.FormEditorWrapper}>
                  <ReactQuill
                    className={styles.FormEditor}
                    modules={quillModules}
                    value={leadForm.content}
                    onChange={this.createOnChangeHandler("content")}
                  />
                  <div
                    onMouseEnter={this.onMouseOver}
                    onMouseLeave={this.onMouseOut}
                  >
                    <Button button="info" />
                  </div>
                  <FieldInstructions
                    isVisible={this.state.showInstructions}
                    content="Form body text"
                  />
                </div>

                {/* Survey start button text */}
                <SettingsInput
                  label="Start button text"
                  helpText="Survey start button text"
                  type="text"
                  htmlName="startButton"
                  changeState={this.createOnChangeHandler("startButtonText")}
                  value={leadForm.startButtonText}
                  onMouseEnter={this.onMouseOver}
                  onMouseLeave={this.onMouseOut}
                  showInstructions={this.state.showInstructions}
                />
              </div>
              <h2>Form fields</h2>
              {leadFormInputs}
              {leadFormInputsRichTextEditor}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditLeadForm;
