import React from "react";
import styles from "./EditorInfoImage.module.css";
import * as layoutImages from "../../../images/images";

const EditorInfoImage = props => {
  let image = "";
  switch (props.imageType) {
    case "Model page introduction":
      image = <img src={layoutImages.modelTopModule} alt="info" />;
      break;
    // Commented Due to Model page not currently in use
    // case "Model page text for for Tilbyr":
    // case "Model page text for Product & service offering":
    // case "Model page text for Product- & service offering":
    // case "Tilbyr":
    //   image = <img src={layoutImages.modelTilbyr} alt="info" />;
    //   break;
    // case "Model page text for Jobber":
    // case "Model page text for Operations":
    // case "Jobber":
    //   image = <img src={layoutImages.modelJobber} alt="info" />;
    //   break;
    // case "Model page text for Inntekt":
    // case "Model page text for Business model":
    // case "Inntekt":
    //   image = <img src={layoutImages.modelInntekt} alt="info" />;
    //   break;
    // case "Model page text for Oppleves":
    // case "Model page text for Customer experience":
    // case "Oppleves":
    //   image = <img src={layoutImages.modelOppleves} alt="info" />;
    //   break;
    // Pages -> Sidebar
    case "Sidebar content":
      image = <img src={layoutImages.sidebarToolTip} alt="info" />;
      break;
    // Edit survey -> Pages -> Result page
    case "Result details text for Inntekt":
    case "Result details text for Business model":
      image = <img src={layoutImages.resultDetailInntekt} alt="info" />;
      break;
    case "Result details text for Jobber":
    case "Result details text for Operations":
      image = <img src={layoutImages.resultDetailJobber} alt="info" />;
      break;
    case "Result details text for Tilbyr":
    case "Result details text for Product & service offering":
    case "Result details text for Product- & service offering":
      image = <img src={layoutImages.resultDetailTilbyr} alt="info" />;
      break;
    case "Result details text for Oppleves":
    case "Result details text for Customer experience":
      image = <img src={layoutImages.resultDetailOppleves} alt="info" />;
      break;

    default:
  }

  return (
    <div
      className={
        props.show
          ? `${styles.ShowImage} ${styles.Image}`
          : `${styles.HideImage} ${styles.Image}`
      }
    >
      {image}
    </div>
  );
};

export default EditorInfoImage;
