// Result page layout hover images
export const modelTopModule = require("./Model-page-top.png");
export const resultDetailInntekt = require("./result-page-inntekt.png");
export const resultDetailJobber = require("./result-page-jobber.png");
export const resultDetailOppleves = require("./result-page-oppleves.png");
export const resultDetailTilbyr = require("./result-page-tilbyr.png");

// Pages -> Model page
// Change images before using the code below
// export const modelTilbyr = require("./Model-page-tilbyr.jpg");
// export const modelJobber = require("./Model-page-jobber.jpg");
// export const modelInntekt = require("./Model-page-inntekt.jpg");
// export const modelOppleves = require("./Model-page-oppleves.jpg");

//Side bar
export const sidebarToolTip = require("./Sidebar-tooltip.png");
