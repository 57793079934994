import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";
import styles from "./SurveyContainer.module.css";
import { Route, Switch, withRouter } from "react-router-dom";
import SurveyList from "../../components/SurveyList/SurveyList";
import IndividualSurveyOptions from "../../components/IndividualSurveyOptions/IndividualSurveyOptions";
import EditQuestions from "../EditQuestions/EditQuestions";
import EditLayout from "../EditLayout/EditLayout";
import EditSettings from "../EditSettings/EditSettings";
import LastSubmissions from "../LastSubmissions/LastSubmissions";
import Doormat from "../Doormat/Doormat";
import EditIndividualQuestion from "../EditIndividualQuestion/EditIndividualQuestion";
import SubmissionDetails from "../SubmissionDetails/SubmissionDetails";
import Industries from "../Industries/Industries";
import LeadForm from "../LeadForm/LeadForm";

class SurveyContainer extends Component {
  componentDidMount() {
    this.props.onGetSurveys();
  }

  render() {
    return (
      <div className={styles.SurveyContainer}>
        <Switch>
          <Route
            exact
            path="/surveys"
            render={props => (
              <SurveyList
                {...props}
                allSurveys={this.props.allSurveys}
                loading={this.props.loading}
              />
            )}
          />
          <Route exact path="/surveys/doormat" component={Doormat} />
          <Route path="/surveys/:id/pages" component={EditLayout} />
          <Route
            path="/surveys/:id/questions/:questionId"
            component={EditIndividualQuestion}
          />
          <Route path="/surveys/:id/questions" component={EditQuestions} />
          <Route path="/surveys/:id/settings" component={EditSettings} />
          <Route
            exact={false}
            path="/surveys/:id/submissions"
            component={LastSubmissions}
          />
          <Route path="/surveys/:id" component={IndividualSurveyOptions} />
          <Route path="/industries" component={Industries} />
          <Route path={["/forms/:formId", "/forms"]} component={LeadForm} />
        </Switch>
        <Route
          exact
          path="/surveys/:id/submissions/:submissionId"
          component={SubmissionDetails}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allSurveys: state.surveyReducer.allSurveys,
    loading: state.surveyReducer.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSurveys: () => dispatch(actions.getSurveys()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SurveyContainer)
);
