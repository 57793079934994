import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as submissionsActions from "../../store/actions/submissions";
import * as surveyActions from "../../store/actions/actions";
import styles from "./SubmissionDetails.module.css";
import axios from "../../axiosAPIInstances/axios-survey";
import Spinner from "../../components/Spinner/Spinner";

class SubmissionDetails extends Component {
  state = {
    updatedOptions: [],
    enableEdit: false,
    mappedSections: [],
    submission: {
      isFetching: true,
    },
  };

  componentDidMount() {
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
    this.fetchSubmissionToState(this.props.match.params.submissionId);
  }

  componentDidUpdate() {
    // Since we're mounting/unmounting the modal there is no need to compare previous props/state.
    // Map the submission to the survey once we have the survey and submission data.
    if (
      this.state.mappedSections.length === 0 &&
      !this.props.isFetchingSurvey &&
      !this.state.submission.isFetching
    ) {
      const mappedSections = this.mapCurrentSubmissionToSurvey();
      console.debug("Mapped submission", mappedSections);

      // Store the mapped sections in the state.
      this.setState({ mappedSections });
    }
  }

  closeModal() {
    this.props.history.push(
      `/surveys/${this.props.match.params.id}/submissions`
    );
  }

  fetchSubmissionToState(submissionId) {
    this.setState({
      submission: {
        ...this.state.submission,
        isFetching: true,
      },
    });

    axios
      .get(`/submission/${submissionId}`)
      .then(response => {
        this.setState({
          submission: {
            ...response.data,
            isFetching: false,
          },
        });
      })
      .catch(error => {
        console.error(error);
        this.closeModal();
      });
  }

  mapCurrentSubmissionToSurvey() {
    // Closure for grouping an array of objects by a property.
    function groupBy(objectArray, propertyName) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[propertyName];
        acc[key] = obj;
        return acc;
      }, {});
    }

    const submission = this.state.submission;
    const survey = this.props.currentSurvey;

    // Group question submissions by their ID.
    const questionSubmissionsByQuestion = groupBy(
      submission.questionSubmissions,
      "questionId"
    );

    // Group answer option submission by their ID.
    let answerOptionSubmissionsByAnswerOption = {};
    for (const questionSubmission of Object.values(
      questionSubmissionsByQuestion
    )) {
      answerOptionSubmissionsByAnswerOption = {
        ...answerOptionSubmissionsByAnswerOption,
        ...groupBy(questionSubmission.answerOptionSubmissions, "answerOption"),
      };
    }

    // Iterate through the sections and merge all the submission data.
    const output = [];
    const submissionId = submission.id;
    for (const section of survey.sections) {
      const mergedQuestions = [];

      // Merge all the section's questions and their answer options with their submission data (if any exists.)
      for (const question of section.questions) {
        const mergedAnswerOptions = [];

        // Merge the answer options with their submission data (if any exists).
        for (const answerOption of question.answerOptions) {
          const answerOptionSubmission =
            answerOptionSubmissionsByAnswerOption[answerOption.id] || {};
          mergedAnswerOptions.push({
            answerOptionSubmissionId: answerOptionSubmission.id || null,
            selected: answerOptionSubmission.selected || false,
            optionText: answerOption.optionText,
            id: answerOption.id,
            questionId: question.id,
          });
        }

        // Merge question with its submission data (if any exists) and add the processed answer options under it.
        const questionSubmission =
          questionSubmissionsByQuestion[question.id] || {};
        mergedQuestions.push({
          questionSubmissionId: questionSubmission.id || null,
          questionTitle: question.questionTitle,
          questionContent: question.questionContent,
          answerOptions: mergedAnswerOptions,
          id: question.id,
          submissionId: submissionId,
          sectionId: section.id,
        });
      }

      // Add the processed questions under the section.
      output.push({
        id: section.id,
        questions: mergedQuestions,
        name: section.name,
      });
    }

    return output;
  }

  toggleEdit = () => {
    this.setState({
      enableEdit: !this.state.enableEdit,
    });
  };

  onChangeSubmissionOption = updatedOption => {
    // Add modified option ids to the local state. Toggling a boolean value twice will return it to its original value
    // Check if the id exists in the state
    if (
      this.state.updatedOptions.find(option => option.id === updatedOption.id)
    ) {
      // Delete the id from the state if it already exists
      this.setState({
        updatedOptions: this.state.updatedOptions.filter(
          option => option.id !== updatedOption.id
        ),
      });
    } else {
      // if it doesnt exist add the id to the state
      this.setState({
        updatedOptions: [
          ...this.state.updatedOptions,
          { ...updatedOption, selected: !updatedOption.selected },
        ],
      });
    }
  };

  saveSubmission = () => {
    // Get all of the unanswered questions, i.e. questions with no submission ID.
    let unansweredQuestions = [];
    for (const section of this.state.mappedSections) {
      for (const question of section.questions) {
        if (!question.questionSubmissionId) {
          unansweredQuestions.push(question);
        }
      }
    }

    this.props.saveSubmissionDetails(
      this.state.submission.id,
      this.state.updatedOptions,
      unansweredQuestions
    );
    this.closeModal();
  };

  render() {
    const submission = this.state.submission;
    const survey = this.props.currentSurvey;

    if (this.props.isFetchingSurvey || submission.isFetching) {
      // TODO Center the loading spinner.
      return (
        <div className={styles.modalContainer}>
          <div className={styles.modalInner}>
            <Spinner />
          </div>
        </div>
      );
    }

    return (
      this.state.submission && (
        <div className={styles.modalContainer}>
          <div className={styles.modalInner}>
            <div className={styles.SubmissionHeader}>
              <p className={styles.SurveyName}>{survey.settings.name}</p>
              <div className={styles.SubmissionInfo}>
                <p className={styles.CustomLabels}>
                  <span>Submission Date: </span>{" "}
                  {submission.startTime.slice(0, 10)}
                </p>

                <p className={styles.CustomLabels}>
                  <span>Lead Email: </span> {submission.emailAddress}
                </p>

                <p className={styles.CustomLabels}>
                  <span>Company Name:</span> {submission.companyName}
                </p>

                <p className={styles.CustomLabels}>
                  <span>Score: </span>
                  {submission.totalScore}
                </p>
              </div>
            </div>

            <div className={styles.SubmissionSections}>
              {this.state.mappedSections.map((section, index) => (
                <div key={`${index}_${section.name}`}>
                  <h3 className={styles.SectionName}>{section.name}</h3>
                  {section.questions.map((question, index) => (
                    <div key={`${index}_${question.questionTitle}`}>
                      <p className={styles.questions}>
                        {question.questionContent}
                      </p>
                      {question.answerOptions.map((option, index) => (
                        <div key={index} className={styles.answerOptions}>
                          <input
                            id={option.id}
                            onChange={() =>
                              this.onChangeSubmissionOption(option)
                            }
                            defaultChecked={option.selected}
                            className={styles.answerOption_selected}
                            type="checkbox"
                            disabled={!this.state.enableEdit}
                          />
                          <label htmlFor={option.id}>{option.optionText}</label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className={styles.ActionsWrapper}>
              {this.state.enableEdit ? (
                <button
                  className={styles.Actions}
                  onClick={() => this.saveSubmission()}
                >
                  Save
                </button>
              ) : (
                <button
                  className={styles.Actions}
                  onClick={() => this.toggleEdit()}
                >
                  Edit
                </button>
              )}
              <button
                className={styles.Actions}
                onClick={() => this.closeModal()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSurvey: state.surveyReducer.currentSurvey,
    isFetchingSurvey: state.surveyReducer.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSubmissionDetails: (
      submissionId,
      updatedOptions,
      unansweredQuestions
    ) =>
      dispatch(
        submissionsActions.saveSubmissionDetails(
          submissionId,
          updatedOptions,
          unansweredQuestions
        )
      ),
    fetchSurveyIfNeeded: surveyId =>
      dispatch(surveyActions.fetchSurveyIfNeeded(surveyId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubmissionDetails)
);
