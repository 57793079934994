import * as actionTypes from "./actionTypes";
import instance from "../../axiosAPIInstances/axios-survey";
import axios from "axios";
import surveyTemplateEnglish from "../../store/data/survey_ENG";
import surveyTemplateNorwegian from "../../store/data/survey_NOR";

export const startSaving = () => {
  return {
    type: actionTypes.START_SAVING,
  };
};

export const endSaving = () => {
  return {
    type: actionTypes.END_SAVING,
  };
};

export const startFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_START,
  };
};

export const endFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_END,
  };
};

export const setSurveys = surveys => {
  return {
    type: actionTypes.SET_SURVEYS,
    surveys: surveys,
  };
};

export const fetchSurveysFail = () => {
  return {
    type: actionTypes.FETCH_SURVEYS_FAIL,
  };
};

export const deleteSurvey = surveyId => {
  return dispatch => {
    dispatch(startFetching());
    instance.delete(`survey/${surveyId}`).then(() => {
      dispatch({
        type: actionTypes.DELETE_SURVEY,
        id: surveyId,
      });
    });
  };
};

export const copySurvey = surveyId => {
  return dispatch => {
    dispatch(startFetching());
    instance.post(`survey/${surveyId}/copy`).then(() => dispatch(getSurveys()));
  };
};

// selected section
export const getIndex = (index, sectionId) => {
  return {
    type: actionTypes.SET_ACTIVE_INDEX,
    index: index,
    sectionId: sectionId,
  };
};

// list all surveys
export const getSurveys = () => {
  return dispatch => {
    dispatch(startFetching());
    instance
      .get("/survey", { withCredentials: true })
      .then(response => {
        dispatch(setSurveys(response.data.reverse()));
      })
      .then(() => {
        dispatch(endFetching());
      })
      .catch(error => {
        dispatch(fetchSurveysFail(error));
      });
  };
};

// Set survey language
export const setSurveyLanguage = language => {
  return {
    type: actionTypes.SET_SURVEY_LANGUAGE,
    language,
  };
};

// add new survey to the app
export const addNewSurvey = language => {
  let surveyTemplate = null;
  // TODO Maybe move the survey templates to backend?
  // TODO Use a constant instead of a magic string for language. E.g. language === LANGUAGE_NO and replace everything "Norsk" with LANGUAGE_NO
  // TODO Switch-case instead of if-else
  if (language === "Norsk") {
    surveyTemplate = surveyTemplateNorwegian;
  } else {
    surveyTemplate = surveyTemplateEnglish;
  }

  // Generate index numbers for sections.
  surveyTemplate.sections.forEach((section, index) => {
    section.index = index;
    // Generate index numbers for section's questions.
    section.questions.forEach((question, index) => {
      question.index = index;
      // Generate index numbers for question's answer options.
      question.answerOptions.forEach((answerOption, index) => {
        answerOption.index = index;
      });
    });
  });

  return dispatch => {
    dispatch(startFetching());
    instance
      .post("/survey", surveyTemplate, { withCredentials: true })
      .then(response => {
        dispatch({
          type: actionTypes.ADD_SURVEY,
          survey: response.data,
        });
      })
      .then(() => {
        dispatch(endFetching());
      })
      .catch(error => {
        console.log(error);
      });
  };
};

// get current survey
export const fetchSurvey = id => {
  return dispatch => {
    dispatch(startFetching());
    dispatch(requestSurvey());
    return instance
      .get(`/survey/${id}`)
      .then(response => {
        dispatch(receiveSurvey(response.data));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      });
  };
};

const requestSurvey = () => {
  return {
    type: actionTypes.REQUEST_SURVEY,
  };
};

const receiveSurvey = currentSurvey => {
  return {
    type: actionTypes.RECEIVE_SURVEY,
    currentSurvey,
  };
};

export const invalidateSurvey = () => {
  return {
    type: actionTypes.INVALIDATE_SURVEY,
  };
};

const shouldFetchSurvey = (state, surveyId) => {
  const {
    currentSurvey: survey,
    isFetching,
    didInvalidate,
  } = state.surveyReducer;

  // FIXME If the app is fetching survey X and then the user decides to open survey Y, survey X will load instead of survey Y.
  if (!survey || !survey.id || survey.id !== surveyId) {
    return true;
  } else if (isFetching) {
    return false;
  } else {
    return didInvalidate;
  }
};

export const fetchSurveyIfNeeded = surveyId => {
  return (dispatch, getState) => {
    if (shouldFetchSurvey(getState(), surveyId)) {
      console.debug("Fetching survey...");
      return dispatch(fetchSurvey(surveyId));
    }
  };
};

export const wantToEditOption = option => {
  return {
    type: actionTypes.SET_CURRENT_OPTION,
    option: option,
  };
};

export const closeModal = index => {
  return {
    type: actionTypes.CLOSE_MODAL,
    index: index,
  };
};

const updateQuestion = question => {
  return {
    type: actionTypes.UPDATE_QUESTION,
    question: question,
  };
};

export const saveQuestion = (surveyId, updatedData, questionId) => {
  return dispatch => {
    dispatch(startSaving());
    dispatch(invalidateSurvey());
    instance
      .patch(`/question/${questionId}`, updatedData)
      .then(response => {
        dispatch(updateQuestion(response.data));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

const addQuestion = question => {
  return {
    type: actionTypes.ADD_QUESTION,
    question: question,
  };
};

export const createQuestion = (surveyId, sectionId) => {
  return dispatch => {
    const newQuestion = {
      isDefault: false,
      isVisible: false,
      questionTitle: "New question",
      questionContent: "New question content here",
      questionType: "",
      answerOptions: [],
      section: sectionId,
    };

    dispatch(startSaving());
    dispatch(invalidateSurvey());
    instance
      .post(`/question`, newQuestion)
      .then(response => {
        dispatch(addQuestion(response.data));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

export const deleteQuestion = (sectionId, questionId) => {
  return dispatch => {
    dispatch(startSaving());
    dispatch(invalidateSurvey());
    instance
      .delete(`/question/${questionId}`)
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_QUESTION,
          questionId,
          sectionId,
        });
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

const addAnswerOption = (sectionId, questionId, answerOption) => {
  return {
    type: actionTypes.ADD_ANSWER_OPTION,
    answerOption,
    sectionId,
    questionId,
  };
};

export const createAnswerOption = (sectionId, questionId) => {
  return dispatch => {
    const newAnswerOption = {
      optionText: "New option text",
      hoverText: "<p>new option hover text</p>",
      exampleText: "New Example text",
      score: 0,
      priority: 0,
      priorityClass: "red",
      recommendationShort: "<p>New option short recommendation text</p>",
      recommendationLong: "<p>New option Long recommendation text</p>",
      ipClass: "",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at dui lacinia, tristique velit viverra, egestas quam. Praesent rutrum lorem nec viverra dignissim. Nunc ac purus justo. Donec eget arcu nec justo tristique finibus. Nunc interdum purus eu lacinia viverra. Mauris lectus arcu, iaculis vel molestie sed, vulputate iaculis felis. Mauris tempus, nulla eu imperdiet feugiat, lacus elit malesuada lacus, quis lacinia est justo non nulla.",
      isDefault: false,
      isVisible: false,
      question: questionId,
    };

    dispatch(startSaving());
    dispatch(invalidateSurvey());
    instance
      .post(`/answerOption`, newAnswerOption)
      .then(response => {
        dispatch(addAnswerOption(sectionId, questionId, response.data));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

export const deleteOption = (sectionId, questionId, answerOptionId) => {
  return dispatch => {
    dispatch(startSaving());
    dispatch(invalidateSurvey());
    instance
      .delete(`/answerOption/${answerOptionId}`)
      .then(() => {
        dispatch({
          type: actionTypes.DELETE_ANSWER_OPTION,
          questionId,
          sectionId,
          answerOptionId,
        });
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

export const saveAnswerOption = (surveyId, updatedData, optionId) => {
  return dispatch => {
    dispatch(startSaving());
    dispatch(invalidateSurvey());
    dispatch(closeModal());
    instance
      .put(`/answerOption/${optionId}`, updatedData)
      .then(response => {
        dispatch({
          type: actionTypes.UPDATE_ANSWER_OPTION,
          answerOption: response.data,
        });
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endSaving());
      });
  };
};

export const saveSectionSettings = (sectionId, newSettings, image) => {
  return dispatch => {
    dispatch(startSaving());
    const promises = [];
    if (image) {
      promises.push(
        instance
          .patch(`/image/${image.id}`, {
            imgUrl: image.imgUrl,
          })
          .then(response => {
            dispatch({
              type: actionTypes.UPDATE_SECTION_SETTINGS,
              data: { backgroundImage: response.data },
              sectionId: sectionId,
            });
          })
      );
    }

    promises.push(
      instance.patch(`/section/${sectionId}`, newSettings).then(() => {
        dispatch({
          type: actionTypes.UPDATE_SECTION_SETTINGS,
          data: newSettings,
          sectionId: sectionId,
        });
      })
    );

    axios.all(promises).finally(() => {
      dispatch(endSaving());
    });
  };
};

export const savePageLayout = (
  surveyId,
  updatedData,
  currentModuleId,
  saveType
) => {
  return dispatch => {
    let payload = {};
    let endpoint = "";

    switch (saveType) {
      case "sidebar":
        payload.sidebarContent = updatedData;
        endpoint = `/settings/${currentModuleId}`;
        break;
      case "resultPage":
        payload.resultPageContent = updatedData;
        endpoint = `/settings/${currentModuleId}`;
        break;
      case "resultDetails":
        payload.resultPageContent = updatedData;
        endpoint = `/section/${currentModuleId}`;
        break;
      case "noAnswer":
        payload.noAnswerText = updatedData;
        endpoint = `/section/${currentModuleId}`;
        break;
      default:
    }

    dispatch(startFetching());
    dispatch(invalidateSurvey());

    instance
      .patch(endpoint, payload)
      .then(() => {
        dispatch(fetchSurveyIfNeeded(surveyId));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      });

    dispatch(endFetching());
  };
};

export const saveSettings = (surveyId, updatedData, settingsId) => {
  return dispatch => {
    dispatch(startFetching());
    dispatch(invalidateSurvey());

    instance
      .patch(`/settings/${settingsId}`, updatedData)
      .then(() => {
        dispatch(fetchSurveyIfNeeded(surveyId));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      })
      .finally(() => {
        dispatch(endFetching());
      });
  };
};

export const saveVisibility = (modelId, surveyId, updatedData) => {
  return dispatch => {
    dispatch(startFetching());
    dispatch(invalidateSurvey());
    instance
      .patch(`/model/${modelId}`, updatedData, { withCredentials: true })
      .then(() => {
        dispatch(fetchSurveyIfNeeded(surveyId));
      })
      .catch(() => {
        dispatch(fetchSurveysFail());
      });
  };
};
