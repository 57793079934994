import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// containers and actions
import * as actions from "../../store/actions/actions";
import styles from "./Question.module.css";
import Button from "../../components/UI/Button/Button/Button";
import AnswerOption from "../AnswerOption/AnswerOption";
import AnswerOptionHeaders from "../AnswerOption/AnswerOptionHeaders";
import "react-quill/dist/quill.snow.css"; // ES6
import PropTypes from "prop-types";
import { confirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";

class Question extends Component {
  state = {
    expandedQuestion: false,
    editing: false,
    isVisible: false,
    questionTitle: "",
    questionContent: "",
  };

  clickDeleteQuestion() {
    confirmDialog({
      text: "Are you sure you want to delete this question?",
      onConfirm: this.props.onDelete,
    });
  }

  saveQuestion(id) {
    let questionToSave = {
      questionTitle: this.state.questionTitle,
      questionContent: this.state.questionContent,
      isVisible: this.state.isVisible,
      isDefault: this.props.isDefault,
    };

    this.props.onSaveQuestion(this.props.match.params.id, questionToSave, id);
  }

  addAnswerOption() {
    this.props.onAddAnswerOption(this.props.sectionId, this.props.id);
  }

  deleteAnswerOption(answerOptionId) {
    confirmDialog({
      text: "Are you sure you want to delete this option?",
      onConfirm: () =>
        this.props.deleteAnswerOption(
          this.props.sectionId,
          this.props.id,
          answerOptionId
        ),
    });
  }

  textEditorChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  selectInputField = e => {
    this.setState({ isVisible: e.target.checked });
  };

  componentDidMount() {
    this.setState({
      questionTitle: this.props.title,
      questionContent: this.props.content,
      isVisible: this.props.isVisible,
    });
  }

  componentDidUpdate(prevProps) {
    // resets the state if you close change the section
    if (this.props.activeIndex.index !== prevProps.activeIndex.index) {
      this.setState({
        expandedQuestion: false,
        editing: false,
        questionTitle: "",
        questionContent: "",
      });
    }
  }

  render() {
    const editAndDeleteQuestion = (
      <div className={styles.ButtonsContainer}>
        <div
          onClick={() => this.props.onExpand(this.props.id)}
          className={styles.Button}
        >
          <Button button="edit" />
        </div>
        {this.props.disableDelete === false && !this.props.isDefault ? (
          <div
            onClick={this.clickDeleteQuestion.bind(this, this.props.id)}
            className={styles.Button}
          >
            <Button button="delete" />
          </div>
        ) : null}
      </div>
    );

    const saveAndClose = (
      <div className={styles.ButtonsContainer}>
        <div
          onClick={this.saveQuestion.bind(this, this.props.id)}
          className={styles.Button}
        >
          <Button button="save" />
        </div>
        <div
          onClick={() => this.props.onShrink(this.props.id)}
          className={styles.Button}
        >
          <Button button="close" />
        </div>
      </div>
    );

    // TODO Move this & the answer option headers under a container.
    const answerOptions = this.props.answerOptions.map((option, index) => {
      return (
        <AnswerOption
          enableDelete={
            this.props.answerOptions.length > 1 && !option.isDefault
          }
          key={index}
          index={index}
          id={option.id}
          option={option}
          onDelete={() => this.deleteAnswerOption(option.id)}
        />
      );
    });

    return (
      <div className={this.props.isExpanded ? styles.Expand : styles.Question}>
        <div>
          {this.props.isExpanded ? (
            this.props.isDefault ? (
              <div>
                <div>
                  <div
                    className={`${styles.QuestionLabels} ${
                      styles.AlignCheckbox
                    }`}
                  >
                    Visible in the survey
                  </div>
                  <input
                    onChange={event => this.selectInputField(event)}
                    checked={this.state.isVisible}
                    type="checkbox"
                  />
                </div>

                <div>
                  <div className={styles.QuestionLabels}>Question Title</div>
                  <p className={styles.DefaultBackground}>{this.props.title}</p>
                </div>

                <div>
                  <div className={styles.QuestionLabels}>
                    Question Description
                  </div>
                  <input
                    className={styles.QuestionInput}
                    name="questionContent"
                    value={this.state.questionContent}
                    onChange={this.textEditorChange}
                    type="text"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.QuestionLabels}>Question Title</div>
                <input
                  className={styles.QuestionInput}
                  name="questionTitle"
                  value={this.state.questionTitle}
                  onChange={this.textEditorChange}
                  type="text"
                />
                <div>
                  <div
                    className={`${styles.QuestionLabels} ${
                      styles.AlignCheckbox
                    }`}
                  >
                    Visible in the survey
                  </div>
                  <input
                    onChange={event => this.selectInputField(event)}
                    checked={this.state.isVisible}
                    type="checkbox"
                  />
                </div>
                <div className={styles.QuestionLabels}>
                  Question Description
                </div>
                <input
                  className={styles.QuestionInput}
                  name="questionContent"
                  value={this.state.questionContent}
                  onChange={this.textEditorChange}
                  type="text"
                />
              </div>
            )
          ) : (
            this.props.title
          )}
        </div>
        <div>
          {this.props.isExpanded ? saveAndClose : editAndDeleteQuestion}
        </div>
        <AnswerOptionHeaders />
        <div className={styles.OptionsWrapper}>{answerOptions}</div>
        <div
          className={styles.AddOption}
          onClick={this.addAnswerOption.bind(this, this.props.id)}
        >
          Add new option +{" "}
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  onShrink: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
};

//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    activeIndex: state.surveyReducer.activeIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveQuestion: (surveyId, questionToSave, questionId) =>
      dispatch(actions.saveQuestion(surveyId, questionToSave, questionId)),
    onAddAnswerOption: (sectionId, questionId) =>
      dispatch(actions.createAnswerOption(sectionId, questionId)),
    deleteAnswerOption: (sectionId, questionId, answerOptionId) =>
      dispatch(actions.deleteOption(sectionId, questionId, answerOptionId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Question)
);
