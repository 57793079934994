import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//reducers
import surveyReducer from "./store/reducers/reducer";
import authenticationReducer from "./store/reducers/authentication";
import submissionsReducer from "./store/reducers/submissions";
import doormatReducer from "./store/reducers/doormat";
import leadFormsReducer from "./store/reducers/leadform.reducer";
import axios from "./axiosAPIInstances/axios-survey";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  surveyReducer: surveyReducer,
  authenticationReducer: authenticationReducer,
  submissionsReducer: submissionsReducer,
  doormatReducer: doormatReducer,
  leadForms: leadFormsReducer,
});
//creates Store
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// Subscription for the API token
store.subscribe(() => {
  const token = store.getState().authenticationReducer.token;
  if (token) {
    if (sessionStorage.getItem("TOKEN") !== token) {
      sessionStorage.setItem("TOKEN", token);
      console.debug("Set TOKEN to session storage.");
    }
    // TODO Maybe do this somewhere else?
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  } else {
    sessionStorage.removeItem("TOKEN");
    console.debug("Removed TOKEN from session storage.");
    // TODO Maybe do this somewhere else?
    axios.defaults.headers.common["Authorization"] = null;
  }
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export { store };
