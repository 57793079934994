import React, { Component } from "react";
import Survey from "../Survey/Survey";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";
import styles from "./SurveyList.module.css";
import { withRouter } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import AddButton from "../UI/Button/AddButton/AddButton";

class SurveyList extends Component {
  handleChange = e => {
    this.props.setSurveyLanguage(e.target.value);
  };

  addNewSurvey = lang => {
    this.props.onNewAddSurvey(lang);
  };

  render() {
    const languageToggle = (
      <label>
        Select template language:
        <select value={this.props.surveyLanguage} onChange={this.handleChange}>
          <option value="Norsk">Norsk</option>
          <option value="English">English</option>
        </select>
      </label>
    );

    let surveyComponent = this.props.allSurveys
      .map((survey, index) => {
        return <Survey key={index} id={survey.id} title={survey.name} />;
      })
      .reverse();
    return (
      <div className={styles.ListWrap}>
        {this.props.loading === true ? (
          <Spinner />
        ) : (
          <div className={styles.ListWrapInner}>
            <div className={styles.NewSurvey}>
              <div className={styles.Language}>{languageToggle}</div>
              <div
                className={styles.AddButton}
                onClick={() => this.addNewSurvey(this.props.surveyLanguage)}
              >
                <AddButton />
              </div>
            </div>
            <div className={styles.SurveyList}>{surveyComponent}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    surveyLanguage: state.surveyReducer.surveyLanguage,
    allSurveys: state.surveyReducer.allSurveys,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSurveyLanguage: e => dispatch(actions.setSurveyLanguage(e)),
    onNewAddSurvey: lang => dispatch(actions.addNewSurvey(lang)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SurveyList)
);
