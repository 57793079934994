import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import styles from "./EditorModule.module.css";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import EditorInfoImage from "../../components/UI/EditorInfoImage/EditorInfoImage";
import Button from "../../components/UI/Button/Button/Button";

class EditorModule extends Component {
  state = {
    moduleContent: "",
    showInfoImage: false,
  };
  //rich tech editor styles
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }], // lists
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ color: [] }], // dropdown with defaults from theme
    [{ align: [] }], // text aligning
    ["clean"], // remove formatting button
  ];
  modules = {
    toolbar: this.toolbarOptions,
  };
  componentDidMount() {
    this.setState({
      moduleContent: this.props.content,
    });
  }

  richTextEditorChange = value => {
    this.setState({ moduleContent: value });
  };

  componentDidUpdate(prevProps) {
    // resets the state if you close change the section
    if (this.props.activeIndex.index !== prevProps.activeIndex.index) {
      this.setState({
        moduleContent: this.props.content,
      });
    }
    if (this.props.content !== prevProps.content) {
      this.setState({
        moduleContent: this.props.content,
      });
    }
  }

  // show and hide the info image
  onMouseOut = () => {
    this.setState({ showInfoImage: false });
  };
  onMouseOver = () => {
    this.setState({ showInfoImage: true });
  };

  saveLayout = () => {
    //send to redux the updated state from rich text editor
    let updatedModule = this.state.moduleContent;
    this.props.onSavePageLayout(
      this.props.match.params.id,
      updatedModule,
      this.props.id,
      this.props.saveType
    );
  };

  render() {
    return (
      <div className={styles.EditorModuleWrapper}>
        <div className={styles.InfoWrapper}>
          <p className={styles.ModuleTitle}>{this.props.title}</p>
          {this.props.showInstructions ? (
            <div className={styles.EditorWrapper}>
              <div
                className={styles.InfoButton}
                onMouseEnter={this.onMouseOver}
                onMouseLeave={this.onMouseOut}
              >
                <Button button="info" />
              </div>
              <EditorInfoImage
                imageType={this.props.title}
                show={this.state.showInfoImage}
                imageStatic={this.props.saveType}
              />
              <div
                className={styles.SaveButton}
                onClick={() => this.saveLayout()}
              >
                <Button button="save" />
              </div>
            </div>
          ) : null}
        </div>
        <ReactQuill
          modules={this.modules}
          value={this.state.moduleContent}
          onChange={this.richTextEditorChange}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    activeIndex: state.surveyReducer.activeIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSavePageLayout: (surveyId, updatedData, currentModuleId, saveType) =>
      dispatch(
        actions.savePageLayout(surveyId, updatedData, currentModuleId, saveType)
      ),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditorModule)
);
