import React, { useState, useEffect, useRef } from "react";
import styles from "./NavigationButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function useOutsideAlterer(ref, setDropdown) {
  //  Alert if clicked on outside of element
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setDropdown(false);
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

// TODO Make this more generic (add "to", "icon" props)
const NavigationButton = props => {
  const [dropdown, setDropdown] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlterer(wrapperRef, setDropdown);

  let icon = "";
  switch (props.icon) {
    case "survey":
      icon = (
        <div
          onClick={() => setDropdown(!dropdown)}
          className={styles.previewButton}
        >
          <div className={styles.icon}>
            <FontAwesomeIcon
              className={styles.bounce}
              style={{ fontSize: "30px" }}
              icon="poll-h"
            />
          </div>
          <div>
            <p className={styles.Link}>{props.label}</p>
          </div>
          {dropdown ? (
            props.industryList.length > 0 ? (
              <div ref={wrapperRef} className={styles.navDropdown}>
                {props.industryList.map((industry, index) => (
                  <a
                    key={`${industry}_${index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_SURVEY_APP_URL}/survey/${props.surveyId}/industry/${industry.id}`}
                  >
                    <div className={styles.navDropdownItem}>
                      {industry.name}
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <div ref={wrapperRef} className={styles.navDropdown}>
                <div className={styles.noIndustries}>
                  No industries assigned to this survey
                </div>
              </div>
            )
          ) : null}
        </div>
      );
      break;
    case "analytics":
      icon = (
        <div>
          <div className={styles.icon}>
            <FontAwesomeIcon style={{ fontSize: "30px" }} icon="chart-line" />
          </div>
          <div>
            <p className={styles.Link}>{props.label}</p>
          </div>
        </div>
      );
      break;
    case "profile":
      icon = (
        <div>
          <div className={styles.icon}>
            <FontAwesomeIcon style={{ fontSize: "30px" }} icon="user-cog" />
          </div>
          <div>
            <p className={styles.Link}>{props.label}</p>
          </div>
        </div>
      );
      break;
    case "logout":
      icon = (
        <div onClick={props.logout}>
          <div className={styles.icon}>
            <FontAwesomeIcon style={{ fontSize: "30px" }} icon="sign-out-alt" />
          </div>
          <div>
            <p className={styles.Link}>{props.label}</p>
          </div>
        </div>
      );
      break;
    case "doormat":
      icon = (
        <Link to={"/surveys/doormat"}>
          <div>
            <div className={styles.icon}>
              <FontAwesomeIcon style={{ fontSize: "30px" }} icon="home" />
            </div>
            <div>
              <p className={styles.Link}>{props.label}</p>
            </div>
          </div>
        </Link>
      );
      break;
    case "surveys":
      icon = (
        <Link to={"/surveys"}>
          <div>
            <div className={styles.icon}>
              <FontAwesomeIcon style={{ fontSize: "30px" }} icon="home" />
            </div>
            <div>
              <p className={styles.Link}>{props.label}</p>
            </div>
          </div>
        </Link>
      );
      break;
    case "leadforms":
      icon = (
        <Link to={"/forms"}>
          <div>
            <div className={styles.icon}>
              <FontAwesomeIcon
                style={{ fontSize: "30px" }}
                icon="clipboard-list"
              />
            </div>
            <div>
              <p className={styles.Link}>{props.label}</p>
            </div>
          </div>
        </Link>
      );
      break;
    case "industries":
      icon = (
        <Link to={"/industries"}>
          <div>
            <div className={styles.icon}>
              <FontAwesomeIcon style={{ fontSize: "30px" }} icon="industry" />
            </div>
            <div>
              <p className={styles.Link}>{props.label}</p>
            </div>
          </div>
        </Link>
      );
      break;
    default:
  }

  return <div className={styles.iconWrapper}>{icon}</div>;
};

export default NavigationButton;
