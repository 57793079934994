import React from "react";
import styles from "./AnswerOptionHeaders.module.css";

const AnswerOptionHeaders = () => {
  return (
    <div className={styles.OptionsHeader}>
      <div className={styles.OptionsDivider}>Options</div>
      <div className={styles.OptionsDivider}>Score</div>
      {/* <div className={styles.OptionsDivider}>Image</div> */}
      <div className={styles.OptionsDivider}>Hover text</div>
      <div className={styles.OptionsDivider}>Visible</div>
      <div className={styles.OptionsDivider}>Priority</div>
      <div className={styles.OptionsDivider}>Priority Class</div>
      <div className={styles.OptionsDivider} />
    </div>
  );
};

export default AnswerOptionHeaders;
