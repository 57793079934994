import React, { Component } from "react";
import * as actions from "../../store/actions/actions";
import * as submissionAction from "../../store/actions/submissions";
import styles from "./Modal.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class Modal extends Component {
  toolbarOptions = ["bold", "italic", "underline", "link"];
  modules = {
    toolbar: this.toolbarOptions,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDefault: props.currentOption.isDefault,
      isVisible: props.currentOption.isVisible,
      optionText: props.currentOption.optionText,
      hoverText: props.currentOption.hoverText,
      exampleText: props.currentOption.exampleText,
      score: props.currentOption.score,
      priority: props.currentOption.priority,
      priorityClass: props.currentOption.priorityClass,
      recommendationShort: props.currentOption.recommendationShort,
      recommendationLong: props.currentOption.recommendationLong,
      ipClass: props.currentOption.ipClass,
      comment: props.currentOption.comment,
    };
  }

  handleHoverTextChange = value => {
    this.setState({ hoverText: value });
  };

  handleCommentChange = value => {
    this.setState({ comment: value });
  };

  handleShortRecommendationChange = value => {
    this.setState({ recommendationShort: value });
  };

  handleLongRecommendationChange = value => {
    this.setState({ recommendationLong: value });
  };

  handleExampleTextChange = value => {
    this.setState({ exampleText: value });
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleIsVisibleChange = e => {
    this.setState({ isVisible: e.target.checked });
  };

  closeModal = () => {
    this.props.onCloseModal();
  };

  deleteSubmission = () => {
    this.props.onDeleteSubmission(this.props.submissionIndex.id);
  };

  saveOption = () => {
    const updatedOption = {
      isDefault: this.state.isDefault,
      isVisible: this.state.isVisible,
      optionText: this.state.optionText,
      hoverText: this.state.hoverText,
      exampleText: this.state.exampleText,
      score: this.state.score,
      priority: this.state.priority,
      priorityClass: this.state.priorityClass,
      recommendationShort: this.state.recommendationShort,
      recommendationLong: this.state.recommendationLong,
      ipClass: this.state.ipClass,
      comment: this.state.comment,
    };

    this.props.onSaveAnswerOption(
      this.props.match.params.id,
      updatedOption,
      this.props.currentOption.id
    );
  };

  render() {
    return (
      <div className={this.props.visible ? styles.ModalVisible : styles.Modal}>
        {this.props.deleteSubmission ? (
          <div className={styles.ModalContent}>
            <p className={styles.InfoText}>
              Are you sure you want to delete this submission?
            </p>
            <div className={styles.ActionsWrapper}>
              <button
                className={styles.Actions}
                onClick={this.deleteSubmission}
              >
                Yes
              </button>
              <button className={styles.Actions} onClick={this.closeModal}>
                Cancel
              </button>
            </div>
          </div>
        ) : null}
        {this.props.editOption && this.props.currentOption ? (
          <div className={styles.ModalContent}>
            <div className={styles.InfoText}>
              {this.state.isDefault ? (
                <div>
                  <div className={styles.Group}>
                    <label
                      className={styles.DefaultLabels}
                      htmlFor="optionText"
                    >
                      Answer option text
                    </label>
                    <input
                      className={styles.DefaultValues}
                      type="text"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.optionText}
                      name="optionText"
                    />
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.DefaultLabels}>Hover text</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.hoverText}
                        onChange={this.handleHoverTextChange}
                      />
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.DefaultLabels}>Example text</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.exampleText}
                        onChange={this.handleExampleTextChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.Group}>
                    <label
                      className={styles.DefaultLabels}
                      htmlFor="optionText"
                    >
                      Answer option text
                    </label>
                    <input
                      className={styles.DefaultValues}
                      type="text"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.optionText}
                      name="optionText"
                    />
                  </div>

                  <div className={styles.Group}>
                    <label className={styles.DefaultLabels}>Hover text</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.hoverText}
                        onChange={this.handleHoverTextChange}
                      />
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.DefaultLabels}>Example text</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.exampleText}
                        onChange={this.handleExampleTextChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.Group}>
                <label className={styles.DefaultLabels}>
                  Visible in the survey
                </label>
                <div className={styles.DefaultValues}>
                  <input
                    className={styles.Checkbox}
                    onChange={event => this.handleIsVisibleChange(event)}
                    defaultChecked={this.state.isVisible}
                    type="checkbox"
                  />
                </div>
              </div>
              {this.state.isDefault ? (
                <div>
                  <div>
                    <div className={styles.Group}>
                      <label className={styles.CustomLabels} htmlFor="score">
                        Score
                      </label>
                      <input
                        className={styles.DefaultValues}
                        type="text"
                        onChange={this.handleInputChange}
                        defaultValue={this.state.score}
                        name="score"
                      />
                    </div>
                    <div className={styles.Group}>
                      <label className={styles.CustomLabels} htmlFor="priority">
                        Priority
                      </label>
                      <input
                        className={styles.DefaultValues}
                        type="text"
                        onChange={this.handleInputChange}
                        defaultValue={this.state.priority}
                        name="priority"
                      />
                    </div>
                    <div className={styles.Group}>
                      <label
                        className={styles.CustomLabels}
                        htmlFor="priorityClass"
                      >
                        Priority Class
                      </label>
                      <select
                        className={styles.SelectInput}
                        onChange={this.handleInputChange}
                        defaultValue={this.state.priorityClass}
                        name="priorityClass"
                        id=""
                      >
                        <option value="green">Green</option>
                        <option value="yellow">Yellow</option>
                        <option value="red">Red</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>
                      Short Recommendation
                    </label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.recommendationShort}
                        onChange={this.handleShortRecommendationChange}
                      />
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>
                      Long Recommendation
                    </label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.recommendationLong}
                        onChange={this.handleLongRecommendationChange}
                      />
                    </div>
                  </div>

                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>Documentation</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.comment}
                        onChange={this.handleCommentChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels} htmlFor="score">
                      Score
                    </label>
                    <input
                      className={styles.DefaultValues}
                      type="text"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.score}
                      name="score"
                    />
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels} htmlFor="priority">
                      Priority
                    </label>
                    <input
                      className={styles.DefaultValues}
                      type="text"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.priority}
                      name="priority"
                    />
                  </div>
                  <div className={styles.Group}>
                    <label
                      className={styles.CustomLabels}
                      htmlFor="priorityClass"
                    >
                      Priority Class
                    </label>
                    <select
                      className={styles.SelectInput}
                      onChange={this.handleInputChange}
                      defaultValue={this.state.priorityClass}
                      name="priorityClass"
                      id=""
                    >
                      <option value="green">Green</option>
                      <option value="yellow">Yellow</option>
                      <option value="red">Red</option>
                    </select>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>
                      Short Recommendation
                    </label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.recommendationShort}
                        onChange={this.handleShortRecommendationChange}
                      />
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>
                      Long Recommendation
                    </label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.recommendationLong}
                        onChange={this.handleLongRecommendationChange}
                      />
                    </div>
                  </div>
                  <div className={styles.Group}>
                    <label className={styles.CustomLabels}>Documentation</label>
                    <div className={styles.Editor}>
                      <ReactQuill
                        modules={this.modules}
                        defaultValue={this.state.comment}
                        onChange={this.handleCommentChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.ActionsWrapper}>
              <button className={styles.Actions} onClick={this.saveOption}>
                Save
              </button>
              <button className={styles.Actions} onClick={this.closeModal}>
                Cancel
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentOption: state.surveyReducer.currentOption,
    activeIndex: state.surveyReducer.activeIndex,
    submissionIndex: state.submissionsReducer.submissionIndex,
    currentSurvey: state.surveyReducer.currentSurvey,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(actions.closeModal()),
    onSaveAnswerOption: (surveyId, updatedOption, optionId) =>
      dispatch(actions.saveAnswerOption(surveyId, updatedOption, optionId)),
    onDeleteSubmission: submissionId =>
      dispatch(submissionAction.deleteSubmission(submissionId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal)
);
