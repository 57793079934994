import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import styles from "./Survey.module.css";
import Button from "../UI/Button/Button/Button";
import editIcon from "../../images/edit.svg";
import { deleteSurvey, copySurvey } from "../../store/actions/actions";
import { confirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";

class Survey extends Component {
  copySurvey = () => {
    console.debug(`Copying ${this.props.id}`);
    this.props.copySurvey(this.props.id);
  };

  deleteSurvey = () => {
    confirmDialog({
      text: `Are you sure you want to delete "${this.props.title}"?`,
      onConfirm: () => this.props.onDeleteSurvey(this.props.id),
    });
  };

  render() {
    return (
      <div className={styles.Survey}>
        {this.props.title}
        <div className={styles.ButtonsContainer}>
          <Link to={`surveys/${this.props.id}`}>
            <Button button="edit" icon={editIcon} />
          </Link>
          <div onClick={() => this.copySurvey()}>
            <Button button="copy" />
          </div>
          <div onClick={() => this.deleteSurvey()}>
            <Button button="delete" />
          </div>
        </div>
      </div>
    );
  }
}

//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    survey: state.surveyReducer.currentSurvey, // comes from redux
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDeleteSurvey: surveyId => dispatch(deleteSurvey(surveyId)),
    copySurvey: surveyId => dispatch(copySurvey(surveyId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Survey)
);
