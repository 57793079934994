import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// containers and actions
import * as actions from "../../store/actions/actions";
import styles from "./AnswerOption.module.css";
import Button from "../../components/UI/Button/Button/Button";
import PropTypes from "prop-types";

class AnswerOption extends Component {
  clickEditOption = () => {
    this.props.onWantToEditOption(this.props.option);
  };

  render() {
    const editAndDeleteOption = (
      <div className={styles.EditRow}>
        <div
          onClick={this.clickEditOption.bind(this, this.props.id)}
          className={styles.Button}
        >
          <Button button="edit" />
        </div>
        {this.props.enableDelete ? (
          <div onClick={this.props.onDelete} className={styles.Button}>
            <Button button="delete" />
          </div>
        ) : null}
      </div>
    );

    return (
      <div
        className={styles.OptionsRow}
        key={this.props.index}
        id={this.props.id}
      >
        <div className={styles.OptionsDivider}>
          <p className={styles.optionText}>
            {`${this.props.option.optionText.substring(0, 25)}...`}
          </p>
        </div>
        <div className={styles.OptionsDivider}>{this.props.option.score}</div>
        <div className={styles.OptionsDivider}>
          {`${this.props.option.hoverText
            .replace(/<\/?[^>]+(>|$)/g, "")
            .substring(0, 25)}...`}
        </div>
        <div className={styles.OptionsDivider}>
          {this.props.option.isVisible ? "yes" : "no"}
        </div>
        <div className={styles.OptionsDivider}>
          {this.props.option.priority}
        </div>
        <div className={styles.OptionsDivider}>
          <div className={styles[`${this.props.option.priorityClass}`]}>
            <div className={styles.priorityClassLabel}>
              {this.props.option.priorityClass}
            </div>
          </div>
        </div>

        <div className={styles.OptionsDivider}>{editAndDeleteOption}</div>
      </div>
    );
  }
}

AnswerOption.propTypes = {
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  enableDelete: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onWantToEditOption: optionId =>
      dispatch(actions.wantToEditOption(optionId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnswerOption)
);
