import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./QuestionsList.module.css";
import PropTypes from "prop-types";
import Question from "../Question/Question";
import produce from "immer";
import * as actions from "../../store/actions/actions";

class QuestionsList extends Component {
  state = {
    expandedQuestions: new Set(),
  };

  onExpand = questionId => {
    console.debug("expanding ", questionId);
    this.setState(
      produce(draft => {
        draft.expandedQuestions.add(questionId);
      })
    );
  };

  onShrink = questionId => {
    console.debug("shrinking ", questionId);
    this.setState(
      produce(draft => {
        draft.expandedQuestions.delete(questionId);
      })
    );
  };

  onDelete = (questionId, sectionId) => {
    console.debug(`deleting ${questionId}`);
    this.props.deleteQuestion(sectionId, questionId);
    // If, for some reason, we have the deleted question expanded, remove it.
    this.setState(
      produce(draft => {
        draft.expandedQuestions.delete(questionId);
      })
    );
  };

  render() {
    const { section } = this.props;

    let question = section.questions.map((question, index) => {
      return (
        <Question
          key={question.id}
          disableDelete={section.questions.length === 1 ? true : false}
          index={index}
          id={question.id}
          sectionId={question.section}
          title={question.questionTitle}
          content={question.questionContent}
          answerOptions={question.answerOptions}
          isDefault={question.isDefault}
          isVisible={question.isVisible}
          isExpanded={this.state.expandedQuestions.has(question.id)}
          onExpand={this.onExpand}
          onShrink={this.onShrink}
          onDelete={() =>
            question.isDefault
              ? null
              : this.onDelete(question.id, question.section)
          }
        />
      );
    });

    return (
      <div className={styles.QuestionsList}>
        <p className={styles.QuestionsListHeader}>Questions</p>
        {question}
      </div>
    );
  }
}

QuestionsList.propTypes = {
  section: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    deleteQuestion: (sectionId, questionId) =>
      dispatch(actions.deleteQuestion(sectionId, questionId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionsList)
);
