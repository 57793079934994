export const SET_SURVEYS = "SET_SURVEYS";
export const FETCH_SURVEYS_FAIL = "FETCH_SURVEYS_FAIL";
export const ADD_SURVEY = "ADD_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const REQUEST_SURVEY = "REQUEST_SURVEY";
export const RECEIVE_SURVEY = "RECEIVE_SURVEY";
export const INVALIDATE_SURVEY = "INVALIDATE_SURVEY";

export const FETCHING_DATA_START = "FETCHING_DATA_START";
export const FETCHING_DATA_END = "FETCHING_DATA_END";
export const SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX";

export const SET_CURRENT_OPTION = "SET_CURRENT_OPTION";

export const CLOSE_MODAL = "CLOSE_MODAL";

export const SET_SURVEY_LANGUAGE = "SET_SURVEY_LANGUAGE";

export const ADD_QUESTION = "ADD_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";

export const ADD_ANSWER_OPTION = "ADD_ANSWER_OPTION";
export const UPDATE_ANSWER_OPTION = "UPDATE_ANSWER_OPTION";
export const DELETE_ANSWER_OPTION = "DELETE_ANSWER_OPTION";

export const UPDATE_SECTION_SETTINGS = "UPDATE_SECTION_SETTINGS";

export const START_SAVING = "START_SAVING";
export const END_SAVING = "END_SAVING";

//authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CHECK_AUTH = "CHECK_AUTH";

//industry
export const SURVEY_INDUSTRY = "SURVEY_INDUSTRY";

//submissions
export const SET_SUBMISSIONS = "SET_SUBMISSIONS";
export const FETCH_SUBMISSIONS_FAIL = "FETCH_SUBMISSIONS_FAIL";
export const SET_CURRENT_SUBMISSION = "SET_CURRENT_SUBMISSION";
export const DELETE_SUBMISSION = "DELETE_SUBMISSION";
export const CONFIRM_DEL_SUBMISSION = "CONFIRM_DEL_SUBMISSION";
export const SET_UPDATED_SUBMISSION = "SET_UPDATED_SUBMISSION"; // Update submission options from last submission view
export const RECEIVE_SUBMISSION = "RECEIVE_SUBMISSION";
export const REQUEST_SUBMISSION = "REQUEST_SUBMISSION";

//doormat
export const GET_DOORMAT_DATA = "GET_DOORMAT_DATA";
export const FETCH_DOORMAT_FAIL = "FETCH_DOORMAT_FAIL";
export const UPDATED_DOORMAT = "UPDATED_DOORMAT";
export const REFRESH_DORMAT = "REFRESH_DORMAT";
