import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NavigationBar from "../../components/NavigationPath/NavigationBar";

class NavigationContainer extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            exact
            path={["/surveys", "/industries"]}
            render={props => <NavigationBar {...props} />}
          />
          <Route
            render={props => (
              <NavigationBar {...props} survey={this.props.survey} />
            )}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    survey: state.surveyReducer.currentSurvey,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationContainer)
);
