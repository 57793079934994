import React from "react";
import styles from "./LoginInput.module.css";
import PropTypes from "prop-types";

const LoginInput = props => {
  return (
    <div className={styles.LoginInput}>
      <label htmlFor={props.name} className={styles.Label}>
        {props.label}
      </label>
      <input
        type={props.type}
        className={styles.Input}
        name={props.name}
        value={props.value}
        autoComplete={props.name}
        onChange={props.onChange}
      />
    </div>
  );
};

LoginInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default LoginInput;
