import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./EditorModulesList.module.css";
import * as actions from "../../store/actions/actions";
import EditorModule from "../EditorModule/EditorModule";
import Button from "../../components/UI/Button/Button/Button";
import Spinner from "../../components/Spinner/Spinner";

class EditorModulesList extends Component {
  // Checkbox for visiblity
  state = {
    isVisible: false,
  };

  // checkbox handler
  isVisible = e => {
    e.persist();
    this.setState({
      isVisible: e.target.checked,
    });
  };
  // save visibilitity save to redux // TODO do we even need visibility?

  saveVisibility(sectionId) {
    let updatedModule = {
      isDefault: this.state.isVisible,
    };
    this.props.onSaveVisiblity(
      sectionId,
      this.props.match.params.id,
      updatedModule
    );
  }

  render() {
    // load the section content from local storage
    const currentSurvey = this.props.currentSurvey;
    if (!currentSurvey.id) {
      return <Spinner />;
    }
    const section = currentSurvey.layout[this.props.index];

    const sidebar = (
      <EditorModule
        showInstructions
        id={currentSurvey.settings.id}
        saveType="sidebar"
        title={`Sidebar content`}
        content={currentSurvey.settings.sidebarContent}
      />
    );
    // TODO In the case that the client would like to edit the no answer text in future versions
    // const noAnswerText = currentSurvey.sections.map((section, index) => {
    //   return (
    //     <EditorModule
    //       key={`noAnswer_${index}`}
    //       showInstructions
    //       id={section.id}
    //       saveType="noAnswer"
    //       title={`Model page text for ${section.name}`}
    //       content={section.noAnswerText}
    //     />
    //   );
    // });

    const modelPageTexts = currentSurvey.sections.map((section, index) => {
      return (
        <EditorModule
          key={`noAnswer_${index}`}
          showInstructions
          id={section.id}
          saveType="modelPage"
          title={`Model page text for ${section.name}`}
          content=""
        />
      );
    });

    const resultPage = (
      <EditorModule
        showInstructions
        id={currentSurvey.settings.id}
        saveType="resultPage"
        title={`Model page introduction`}
        content={currentSurvey.settings.resultPageContent}
      />
    );

    const resultDetails = currentSurvey.sections.map((section, index) => {
      return (
        <EditorModule
          key={`resultDetail_${index}`}
          showInstructions
          id={section.id}
          saveType="resultDetails"
          title={`Result details text for ${section.name}`}
          content={section.resultPageContent}
        />
      );
    });

    return (
      <div className={styles.EditorModulesList}>
        {this.props.activePage === 0 ? (
          <React.Fragment>
            {/* Visibility checkbox and save button*/}
            <div className={styles.VisibilityWrapper}>
              Visible in the survey
              <input
                className={styles.Visible}
                checked={this.state.isVisible}
                onChange={event => this.isVisible(event)}
                type="checkbox"
              />
              <div
                className={styles.Visibility}
                onClick={() => this.saveVisibility(section.id)}
              >
                <Button button="save" />
              </div>
            </div>
            <div>{modelPageTexts}</div>
          </React.Fragment>
        ) : this.props.activePage === 1 ? (
          <React.Fragment>
            <div>{resultPage}</div>
            <div>{resultDetails}</div>
          </React.Fragment>
        ) : (
          <div>{sidebar}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSurvey: state.surveyReducer.currentSurvey,
    loading: state.surveyReducer.loading,
    activeIndex: state.surveyReducer.activeIndex,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSaveVisiblity: (modelId, surveyId, updatedData) =>
      dispatch(actions.saveVisibility(modelId, surveyId, updatedData)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditorModulesList)
);
