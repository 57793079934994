import React, { Component } from "react";
import styles from "./Login.module.css";
import logo from "../../images/Onsagers-logo.png";
import { connect } from "react-redux";
import LoginInput from "../LoginInput/LoginInput";
import PropTypes from "prop-types";

class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  submitHandler = event => {
    event.preventDefault();
    this.props.onSubmit(this.state);
  };

  render() {
    return (
      <div className={styles.Login}>
        <div className={`${styles.Logo} ${styles.First}`}>
          <a
            href="https://onsagers.no/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={logo} alt="onsagers-logo" />
          </a>
        </div>
        <form onSubmit={this.submitHandler}>
          <LoginInput
            label="Username"
            name="username"
            type="text"
            value={this.state.username}
            onChange={this.onChange}
          />
          <LoginInput
            label="Password"
            name="password"
            type="password"
            value={this.props.password}
            onChange={this.onChange}
          />
          <div className={styles.ButtonWrapper}>
            <button className={styles.Button}>LOGIN</button>
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default connect()(Login);
