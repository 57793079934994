import React from "react";
import { Link } from "react-router-dom";
import styles from "./CurrentURL.module.css";
import Spinner from "../../Spinner/Spinner";

const currentURL = props => {
  let url = (
    <div className={styles.Navigation}>
      {" "}
      <Spinner />
    </div>
  );
  if (props.loading === true) {
    url = (
      <div className={styles.Navigation}>
        {" "}
        <Spinner />
      </div>
    );
  } else {
    const surveyLink = (
      <Link className={styles.Active} to={`/surveys/${props.match.params.id}`}>
        {props.survey ? props.survey.name : null}
      </Link>
    );
    switch (props.label) {
      case "industries":
        url = <div className={styles.Navigation}>Industries</div>;
        break;
      case "home":
        url = <div className={styles.Navigation}>Your Surveys</div>;
        break;
      case "home-link":
        url = (
          <div className={styles.Navigation}>
            <Link to="/surveys" className={styles.Active}>
              Your surveys
            </Link>
          </div>
        );
        break;
      case "chosenSurvey":
        url = (
          <div className={styles.Navigation}>
            <Link to="/surveys" className={styles.Active}>
              Your surveys
            </Link>{" "}
            > {`${props.surveyName}`}
          </div>
        );
        break;
      case "questions":
        url = (
          <div className={styles.Navigation}>
            Your surveys > {surveyLink} > Questions
          </div>
        );
        break;
      case "layout":
        url = (
          <div className={styles.Navigation}>
            Your surveys > {surveyLink} > Layout
          </div>
        );
        break;
      case "settings":
        url = (
          <div className={styles.Navigation}>
            Your surveys > {surveyLink} > Settings
          </div>
        );
        break;
      case "lastSubmissions":
        url = (
          <div className={styles.Navigation}>
            Your surveys > {surveyLink} > Submissions
          </div>
        );
        break;
      case "login":
        url = <div className={styles.Navigation}>Login</div>;
        break;
      default:
        break;
    }
  }

  return <div>{url}</div>;
};

export default currentURL;
