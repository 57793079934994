import React from "react";
import styles from "./Button.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const EditButton = props => {
  // TODO Rename to IconButton
  // TODO Maybe just set icon/size directly instead of having separate cases for each button?
  let button = "";
  switch (props.button) {
    case "edit":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="pen" />;
      break;
    case "delete":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="trash" />;
      break;
    case "close":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="times" />;
      break;
    case "save":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="save" />;
      break;
    case "info":
      button = (
        <FontAwesomeIcon style={{ fontSize: "20px" }} icon="info-circle" />
      );
      break;
    case "layout":
      button = (
        <FontAwesomeIcon style={{ fontSize: "30px" }} icon="object-group" />
      );
      break;
    case "survey":
      button = <FontAwesomeIcon style={{ fontSize: "30px" }} icon="poll-h" />;
      break;
    case "view":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="eye" />;
      break;
    case "add":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="plus" />;
      break;
    case "settings":
      button = <FontAwesomeIcon style={{ fontSize: "24px" }} icon="cog" />;
      break;
    case "list":
      button = (
        <FontAwesomeIcon style={{ fontSize: "20px" }} icon="clipboard-list" />
      );
      break;
    case "copy":
      button = <FontAwesomeIcon style={{ fontSize: "20px" }} icon="copy" />;
      break;
    default:
  }

  return (
    <div
      className={`${styles.icon} ${props.disabled ? styles.disabled : ""}`}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {button}
    </div>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  button: PropTypes.string, // TODO Probably should call this "icon", not "button".
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  disabled: false,
};

export default EditButton;
