import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SketchPicker } from "react-color";

import * as actions from "../../store/actions/actions";
import Section from "../../components/UI/Section/Section";
import styles from "./EditQuestions.module.css";
import QuestionsList from "../QuestionsList/QuestionsList";
import Modal from "../../components/Modal/Modal";
import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/UI/Button/Button/Button";
import ImagePicker from "../../components/UI/ImagePicker/ImagePicker";
import imageSet from "../../components/UI/ImagePicker/assets/imageSet";

export class EditQuestions extends Component {
  state = {
    editingSettings: false,
    uploading: false,
    color: null,
    image: null,
    textBackgroundColor: null,
  };

  componentDidMount() {
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
    if (!this.props.isFetching && this.props.survey.id) {
      // TODO Maybe just use the survey data directly?
      this.initializeActiveIndex();
      this.initializeColors();
      this.initializeImage();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isFetching &&
      this.props.survey.id &&
      this.props.survey.id !== prevProps.survey.id
    ) {
      this.initializeActiveIndex();
      this.initializeColors();
      this.initializeImage();
    }

    if (this.props.activeIndex.index !== prevProps.activeIndex.index) {
      this.initializeColors();
      this.initializeImage();
    }
  }

  initializeActiveIndex = () => {
    this.props.onGetIndex(
      this.props.activeIndex.index,
      this.props.survey.sections[0].id
    );
  };

  initializeColors = () => {
    this.setState({
      color: this.props.survey.sections[this.props.activeIndex.index].color,
      textBackgroundColor: this.props.survey.sections[
        this.props.activeIndex.index
      ].textBackgroundColor,
    });
  };

  initializeImage = () => {
    this.setState({
      image: this.props.survey.sections[this.props.activeIndex.index]
        .backgroundImage.imgUrl,
    });
  };

  addQuestion = () => {
    this.props.onAddQuestion(
      this.props.match.params.id,
      this.props.activeIndex.id
    );
  };

  onActiveSection(index, sectionId) {
    this.props.onGetIndex(index, sectionId);
  }

  onColorChange = color => {
    this.setState({ color: color.hex });
  };

  onTextBackgroundChange = color => {
    this.setState({ textBackgroundColor: color.hex });
  };

  onImageChange = image => {
    this.setState({ image });
  };

  expandSettings = () => {
    this.setState({
      editingSettings: !this.state.editingSettings,
    });
  };

  saveSettings = () => {
    const imageId = this.props.survey.sections[this.props.activeIndex.index]
      .backgroundImage.id;
    this.props.saveSectionSettings(
      this.props.activeIndex.id,
      {
        color: this.state.color,
        textBackgroundColor: this.state.textBackgroundColor,
      },
      { imgUrl: this.state.image, id: imageId }
    );
  };

  render() {
    const operations = (
      <div className={styles.ButtonsContainer}>
        {this.state.editingSettings ? (
          <React.Fragment>
            <div onClick={this.saveSettings} className={styles.Button}>
              <Button button="save" />
            </div>
            <div onClick={this.expandSettings} className={styles.Button}>
              <Button button="close" />
            </div>
          </React.Fragment>
        ) : (
          <div onClick={this.expandSettings} className={styles.Button}>
            <Button button="settings" />
          </div>
        )}
      </div>
    );

    let survey = this.props.survey;

    if (this.props.isFetching || !this.props.survey.id) {
      return <Spinner />;
    }

    let getSection = survey.sections.map((section, index) => {
      return (
        <Section
          clicked={() => this.onActiveSection(index, section.id)}
          className={
            this.props.activeIndex.index === index
              ? styles.Active
              : styles.Section
          }
          id={section.id}
          key={index}
          sectionName={section.name}
        />
      );
    });

    let defaultColor = "#FFE300";
    let defaultTextBackground = "#FFFFFF";
    const defaultImage = "https://dummyimage.com/400x400/ffffff/ffffff&text=1";
    let currentImage = null;
    let currentSection = survey.sections[this.props.activeIndex.index];
    let filteredImage = "";

    if (this.props.activeIndex) {
      defaultColor = currentSection.color;
      defaultTextBackground = currentSection.textBackgroundColor;
      currentImage = currentSection.backgroundImage.imgUrl;
      filteredImage = imageSet.find(image => image.url === currentImage);

      // FIXME Temporary fix, otherwise crashes if the image doesn't exist in the image set. Should show "Select category..." instead, disable image select and validate on save.
      if (!filteredImage) {
        filteredImage = imageSet[0];
      }
    }

    return (
      <div className={styles.EditQuestionsWrapper}>
        <div className={styles.SectionsWrapper}>{getSection}</div>
        <div className={styles.QuestionsWrapper}>
          {this.props.loading === true ? (
            <div className={styles.QuestionListSpinner}>
              <Spinner />
            </div>
          ) : (
            <div>
              {this.state.editingSettings ? (
                <div className={styles.SectionSettings}>
                  <div className={styles.ColorPicker}>
                    <p>Select section theme color</p>
                    <SketchPicker
                      disableAlpha={true}
                      color={this.state.color || defaultColor}
                      onChangeComplete={color => this.onColorChange(color)}
                      presetColors={[
                        "#FFE300",
                        "#007091",
                        "#D33737",
                        "#50B963",
                      ]}
                    />
                  </div>
                  <div>
                    <p>Select section background image</p>
                    <ImagePicker
                      url={currentImage ? currentImage : defaultImage}
                      image={filteredImage}
                      onImageChange={this.onImageChange}
                      textBackgroundColor={this.state.textBackgroundColor}
                    />
                  </div>
                  <div className={styles.ColorPicker}>
                    <p>Select section text background</p>
                    <SketchPicker
                      disableAlpha={true}
                      color={
                        this.state.textBackgroundColor || defaultTextBackground
                      }
                      onChangeComplete={this.onTextBackgroundChange}
                      presetColors={[
                        "#FFFFFF",
                        "#C9C9C9",
                        "#FFE300",
                        "#007091",
                        "#D33737",
                        "#50B963",
                      ]}
                    />
                  </div>
                  {operations}
                </div>
              ) : (
                <div className={styles.SectionSettingsCollapsed}>
                  <p>Section Settings</p>
                  {operations}
                </div>
              )}
              <QuestionsList
                index={this.props.activeIndex.index}
                section={currentSection}
              />
              <div
                className={styles.AddButtonWrapper}
                onClick={this.addQuestion}
              >
                Add Question
                <Button button="add" />
              </div>
            </div>
          )}
        </div>
        {this.props.isSaving && (
          <div className={styles.SavingOverlay}>
            <div className={styles.QuestionListSpinner}>
              <Spinner />
            </div>
          </div>
        )}
        {this.props.wantToEditOption ? <Modal editOption /> : null}
      </div>
    );
  }
}

//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    activeIndex: state.surveyReducer.activeIndex,
    survey: state.surveyReducer.currentSurvey,
    currentSection: state.surveyReducer.currentSection,
    wantToEditOption: state.surveyReducer.wantToEditOption,
    loading: state.surveyReducer.loading,
    bgImage: state.surveyReducer.bgImage,
    isFetching: state.surveyReducer.isFetching,
    isSaving: state.surveyReducer.isSaving,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSurveyIfNeeded: surveyId =>
      dispatch(actions.fetchSurveyIfNeeded(surveyId)),
    onGetIndex: (index, sectionId) =>
      dispatch(actions.getIndex(index, sectionId)),
    onAddQuestion: (surveyId, sectionId) =>
      dispatch(actions.createQuestion(surveyId, sectionId)),
    saveSectionSettings: (sectionId, newSettings, image) =>
      dispatch(actions.saveSectionSettings(sectionId, newSettings, image)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditQuestions)
);
