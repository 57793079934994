import React, { Component } from "react";

class Section extends Component {
  render() {
    return (
      <div
        onClick={this.props.clicked}
        active={this.props.active}
        className={this.props.className}>
        <p>{this.props.sectionName}</p>
      </div>
    );
  }
}

export default Section;
