import React from "react";
import styles from "./UserBar.module.css";
import NavigationButton from "../UI/Button/NavigationButton/NavigationButton";
import { withRouter } from "react-router-dom";

const userBar = props => {
  return (
    <div className={styles.UserBar}>
      {props.surveyId &&
      props.match.path &&
      props.match.path.startsWith("/surveys") ? (
        <NavigationButton
          icon="survey"
          label="Preview"
          surveyId={props.surveyId}
          industryList={props.industryList}
        />
      ) : null}

      <NavigationButton icon="leadforms" label="Forms" />
      <NavigationButton icon="surveys" label="Surveys" />
      <NavigationButton icon="industries" label="Industries" />
      <NavigationButton icon="analytics" label="Analytics" />
      <NavigationButton icon="profile" label="Profile" />
      <NavigationButton logout={props.logout} icon="logout" label="Logout" />
    </div>
  );
};

export default withRouter(userBar);
