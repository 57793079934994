import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import SettingsInput from "../../components/UI/SettingsInput/SettingsInput";
import styles from "./EditSettings.module.css";
import Button from "../../components/UI/Button/Button/Button";
import FieldInstructions from "../../components/UI/SettingsInput/FieldInstructions/FieldInstructions";
import Spinner from "../../components/Spinner/Spinner";

export class EditSettings extends Component {
  state = {
    surveyName: "",
    languageCode: "",
    isVisible: null,
    nextButton: "",
    endButton: "",
    industries: [],
    showInstructions: false,
    editorContent: "",
  };

  componentDidMount() {
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
    this.initializeState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.survey.id !== prevProps.survey.id) {
      this.initializeState();
    }
  }

  initializeState() {
    if (!this.props.isFetching && this.props.survey.id) {
      let settings = this.props.survey.settings;

      this.setState({
        surveyName: settings.name,
        languageCode: settings.languageCode,
        isVisible: settings.isVisible,
        industries: this.props.survey.industries,
        startButton: settings.surveyStartButton,
        nextButton: settings.surveyNextButton,
        endButton: settings.surveyEndButton,
      });
    }
  }

  inputChangeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  publishChange = e => {
    this.setState({
      isVisible: e.target.checked,
    });
  };

  // * form text inputs handlers
  inputLeadFormChangeHandler = (e, index, type) => {
    const updatedFormFields = {
      ...this.state.formFields,
    };
    const updatedFormElement = {
      ...updatedFormFields[index],
    };
    if (type === "mandatory") {
      updatedFormElement.isRequired = e.target.checked;
    }
    if (type === "visible") {
      updatedFormElement.isVisible = e.target.checked;
    }
    if (type === "textFields") {
      updatedFormElement.inputLabel = e.target.value;
    }
    if (type === "errorMessage") {
      updatedFormElement.errorMessage = e.target.value;
    }
    updatedFormFields[index] = updatedFormElement;
    this.setState({ formFields: updatedFormFields });
  };

  // * gets the current state and sends it to redux
  saveData = () => {
    let settings = this.props.survey.settings;
    // updates the survey settings and the title for the navigation bar
    let updatedSurveySettings = {
      name: this.state.surveyName,
      isVisible: this.state.isVisible, //boolean
      surveyName: this.state.surveyName, //string
      surveyNextButton: this.state.nextButton, //string
      surveyEndButton: this.state.endButton, //string
    };

    this.props.onSaveSettings(
      this.props.match.params.id,
      updatedSurveySettings,
      settings.id
    );
  };

  onMouseOut = () => {
    this.setState({ showInstructions: false });
  };

  onMouseOver = () => {
    this.setState({ showInstructions: true });
  };

  render() {
    let industries = this.state.industries.map((industry, index) => {
      return (
        <p key={index} className={styles.ReadOnlyValue}>
          {industry.name}
        </p>
      );
    });

    return (
      <div className={styles.Wrapper}>
        {this.props.loading === true ? (
          <div className={styles.QuestionListSpinner}>
            <Spinner />
          </div>
        ) : (
          <div className={styles.SettingsWrapper}>
            <div className={styles.ViewWrapper}>
              <div className={styles.LeadFormWrapper}>
                <div className={styles.SaveSettings} onClick={this.saveData}>
                  <Button button="save" />
                </div>
                <div className={styles.LeadFormInputs}>
                  <h2 className={styles.SectionLabel}>Basic survey settings</h2>
                  <div className={styles.Group}>
                    <div className={styles.Bordered}>
                      <SettingsInput
                        label="Publish survey"
                        helpText="Set survey visibility"
                        type="publish"
                        htmlName="publishSurvey"
                        id={this.props.survey.id}
                        changeState={this.publishChange}
                        isChecked={this.state.isVisible}
                        onMouseEnter={this.onMouseOver}
                        onMouseLeave={this.onMouseOut}
                        showInstructions={this.state.showInstructions}
                      />
                      <SettingsInput
                        label="Survey name"
                        helpText="Name your survey"
                        type="text"
                        htmlName="surveyName"
                        changeState={this.inputChangeHandler}
                        value={this.state.surveyName}
                        onMouseEnter={this.onMouseOver}
                        onMouseLeave={this.onMouseOut}
                        showInstructions={this.state.showInstructions}
                      />
                      <div className={styles.ReadOnlyFieldContainer}>
                        <div>
                          <p className={styles.CustomLabel}>Survey Language</p>
                          <p className={styles.ReadOnlyValue}>
                            {(() => {
                              switch (this.state.languageCode) {
                                case "EN":
                                  return "English";
                                case "NO":
                                  return "Norwegian";
                                default:
                                  return null;
                              }
                            })()}
                          </p>
                        </div>
                        <div
                          className={styles.EditorInstructions}
                          onMouseEnter={this.onMouseOver}
                          onMouseLeave={this.onMouseOut}
                        >
                          <Button button="info" />
                        </div>
                        <FieldInstructions
                          isVisible={this.state.showInstructions}
                          content="Shows survey language"
                        />
                      </div>
                      <div className={styles.ReadOnlyFieldContainer}>
                        <div>
                          <p className={styles.CustomLabel}>Industries</p>
                          {industries}
                        </div>
                        <div
                          className={styles.EditorInstructions}
                          onMouseEnter={this.onMouseOver}
                          onMouseLeave={this.onMouseOut}
                        >
                          <Button button="info" />
                        </div>
                        <FieldInstructions
                          isVisible={this.state.showInstructions}
                          content={`Industries associated with this survey. This is a read-only value; these can be edited in the "Industries" panel.`}
                        />
                      </div>
                      <SettingsInput
                        label="Next button text"
                        helpText="Survey next button text"
                        type="text"
                        htmlName="nextButton"
                        changeState={this.inputChangeHandler}
                        value={this.state.nextButton}
                        onMouseEnter={this.onMouseOver}
                        onMouseLeave={this.onMouseOut}
                        showInstructions={this.state.showInstructions}
                      />
                      <SettingsInput
                        label="End button text"
                        helpText="Survey end button text"
                        type="text"
                        htmlName="endButton"
                        changeState={this.inputChangeHandler}
                        value={this.state.endButton}
                        onMouseEnter={this.onMouseOver}
                        onMouseLeave={this.onMouseOut}
                        showInstructions={this.state.showInstructions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  survey: state.surveyReducer.currentSurvey,
  loading: state.surveyReducer.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchSurveyIfNeeded: surveyId =>
      dispatch(actions.fetchSurveyIfNeeded(surveyId)),
    onSaveSettings: (surveyId, updatedData, settingsId) =>
      dispatch(actions.saveSettings(surveyId, updatedData, settingsId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditSettings)
);
