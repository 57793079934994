import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./LastSubmissions.module.css";
import * as submissionsActions from "../../store/actions/submissions";
import * as actions from "../../store/actions/actions";
import SubmissionHeaders from "../../components/SubmissionHeaders/SubmissionHeaders";
import SubmissionsList from "../SubmissionsList/SubmissionsList";
import Modal from "../../components/Modal/Modal";

class LastSubmissions extends Component {
  componentDidMount() {
    this.props.onGetSubmissions(this.props.match.params.id);
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
  }

  render() {
    return (
      <div className={styles.SubmissionsWrapper}>
        <div className={styles.Table}>
          <SubmissionHeaders />
          <SubmissionsList submissions={this.props.submissions} />
        </div>
        {this.props.wantToDeleteSubmission ? <Modal deleteSubmission /> : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  submissions: state.submissionsReducer.submissions,
  wantToDeleteSubmission: state.submissionsReducer.wantToDeleteSubmission,
});

const mapDispatchToProps = dispatch => {
  return {
    onGetSubmissions: (surveyId) => dispatch(submissionsActions.getSubmissions(surveyId)),
    fetchSurveyIfNeeded: getSurveyId => dispatch(actions.fetchSurveyIfNeeded(getSurveyId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LastSubmissions)
);
