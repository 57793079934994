import React, { Component } from "react";
import SurveyContainer from "../SurveyContainer/SurveyContainer";
import NavigationContainer from "../NavigationContainer/NavigationContainer";
import { withRouter, Redirect } from "react-router-dom";

class SurveyWrapperContainer extends Component {
  render() {
    if (this.props.isAuthenticated) {
      return (
        <div>
          <div className="App">
            <div className="Surveys">
              <NavigationContainer />
              <SurveyContainer />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>you are not isAuthenticated</p>
          <Redirect to="/login" />;
        </div>
      );
    }
  }
}

export default withRouter( SurveyWrapperContainer);
