import * as actionTypes from "../actions/actionTypes";

const initialState = {
  surveyList: [],
  submissions: [],
  currentSubmission: null,
  wantToDeleteSubmission: false,
  loading: false,
  submissionIndex: {
    index: "",
    id: "",
  },
  isFetching: false,
};

const submissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUBMISSIONS:
      return {
        ...state,
        submissions: action.submissions,
      };
    case actionTypes.SET_CURRENT_SUBMISSION:
      return {
        ...state,
        currentSubmission: action.currentSubmission,
      };
    case actionTypes.DELETE_SUBMISSION:
      return {
        ...state,
        wantToDeleteSubmission: true,
        submissionIndex: {
          index: action.index,
          id: action.submissionId,
        },
      };
    case actionTypes.CONFIRM_DEL_SUBMISSION:
      return {
        ...state,
        loading: false,
        submissions: state.submissions.filter(
          submission => submission.id !== action.id
        ),
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        currentSubmission: null,
        wantToDeleteSubmission: false,
      };
    case actionTypes.REQUEST_SUBMISSION:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.RECEIVE_SUBMISSION:
      return {
        ...state,
        isFetching: false,
        currentSubmission: action.submission,
      };
    default:
      return state;
  }
};
export default submissionsReducer;
