import * as actionTypes from "./actionTypes";
import { closeModal } from "./actions";
import instance from "../../axiosAPIInstances/axios-survey";
import axios from "axios";

export const startFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_START,
  };
};

export const endFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_END,
  };
};
export const setSubmissions = submissions => {
  return {
    type: actionTypes.SET_SUBMISSIONS,
    submissions: submissions,
  };
};

export const fetchSubmissionsFail = () => {
  return {
    type: actionTypes.FETCH_SUBMISSIONS_FAIL,
  };
};

export const getSubmissions = surveyId => {
  return dispatch => {
    dispatch(startFetching());
    instance
      .get(`/submission?survey=${surveyId}`)
      .then(response => {
        dispatch(setSubmissions(response.data));
      })
      .then(data => {
        dispatch(endFetching());
      });
  };
};

export const saveSubmissionDetails = (
  submissionId,
  options,
  unansweredQuestions = []
) => {
  return dispatch => {
    dispatch(startFetching());
    // Array of promises; this is used later end fetching after all of the promises are finished.
    const promises = [];

    // Get all of the updated options that *have* a submission ID and format the data for the payload.
    const updateOptions = options
      .filter(option => option.answerOptionSubmissionId)
      .map(option => {
        return {
          id: option.answerOptionSubmissionId,
          selected: option.selected,
        };
      });

    // Update all of the answer options with a submission ID.
    promises.push(
      instance.patch(`/answerOptionSubmission/bulk_update`, updateOptions)
    );

    // If there's any unanswered questions (i.e. questions with no submission ID), create new submissions for them.
    for (const question of unansweredQuestions) {
      // Get the updated options for the unanswered question as answer option ID -> true/false dictionary.
      const questionUpdatedOptions = options
        .filter(option => option.questionId === question.id)
        .reduce((acc, obj) => {
          acc[obj.id] = obj.selected;
          return acc;
        }, {});

      // Create the payload for the answer option submissions.
      const answerOptionSubmissions = question.answerOptions.map(option => {
        return {
          answerOption: option.id,
          selected: questionUpdatedOptions[option.id] || false,
        };
      });

      // Payload for the question submission.
      const newQuestionSubmission = {
        answerOptionSubmissions,
        sectionId: question.sectionId,
        submission: question.submissionId,
        questionId: question.id,
      };

      // Create a new question submission with the answers.
      promises.push(
        instance.post(`/questionSubmission`, newQuestionSubmission)
      );
    }

    // End fetching when all of the requests have finished.
    axios
      .all(promises)
      .then(() => {
        if (process.env.NODE_ENV === "development") {
          console.warn(
            "App is running in development environment; updated lead data was not sent to Pardot."
          );
          dispatch(endFetching());
        } else {
          // Finish the submission, i.e. send the data to Pardot.
          instance
            .post(`/submission/${submissionId}/finish?sendResults=false`)
            .finally(() => dispatch(endFetching()));
        }
      })
      .catch(error => {
        dispatch(fetchSubmissionsFail(error));
      });
  };
};

export const wantToDeleteSubmission = (index, submissionId) => {
  return {
    type: actionTypes.DELETE_SUBMISSION,
    index,
    submissionId,
  };
};

export const deleteSubmission = submissionId => {
  return dispatch => {
    console.log("Delete this Submission", submissionId);
    dispatch(startFetching());
    dispatch(closeModal());
    // axios call will go here
    instance
      .delete(`/submission/${submissionId}`, {
        withCredentials: true,
      })
      .then(response => {
        console.log("Submission_RES", response.data);
        dispatch({
          type: actionTypes.CONFIRM_DEL_SUBMISSION,
          id: submissionId,
        });
      })
      .then(dispatch(endFetching()))
      .catch(error => {
        dispatch(fetchSubmissionsFail());
      });
  };
};
