import * as actionTypes from "../actions/actionTypes";

const initialState = {
  doormatData: {},
  updatedDoormat: {},
  loading: false,
  reloadPage: false
};

const submissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DATA_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.FETCHING_DATA_END:
      return {
        ...state,
        loading: false
      };
    case actionTypes.GET_DOORMAT_DATA:
      return {
        ...state,
        doormatData: action.data,
      };
    case actionTypes.UPDATED_DOORMAT:
      return {
        doormatData: action.data,
      };
    case actionTypes.REFRESH_DORMAT:
      return {
        reloadPage: true
      };
    default:
      return state;
  }
};
export default submissionsReducer;
