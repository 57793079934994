import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Submission from "../Submission/Submission";
import styles from "./SubmissionsList.module.css";

class SubmissionsList extends Component {
  render() {
    let submission = this.props.submissions.map((submission, index) => {
      return (
        <Submission
          key={index}
          id={submission.id}
          date={submission.startTime}
          companyName={submission.companyName}
          email={submission.emailAddress}
          score={submission.totalScore}
          progress={submission.progress}
        />
      );
    });
    return <div className={styles.submissionsWrapper}>{submission}</div>;
  }
}
const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubmissionsList)
);
