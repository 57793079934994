import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import * as authenticationActions from "./store/actions/authentication";
import SurveyWrapperContainer from "./containers/SurveyWrapperContainer/SurveyWrapperContainer";

import {
  faPen,
  faTrash,
  faTimes,
  faSave,
  faPlus,
  faInfoCircle,
  faObjectGroup,
  faPollH,
  faChartLine,
  faUserCog,
  faSignOutAlt,
  faHome,
  faEye,
  faClipboardList,
  faCog,
  faCopy,
  faIndustry,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import Login from "./components/Login/Login";

library.add(
  faPen,
  faTrash,
  faTimes,
  faSave,
  faPlus,
  faInfoCircle,
  faObjectGroup,
  faPollH,
  faChartLine,
  faUserCog,
  faSignOutAlt,
  faHome,
  faEye,
  faClipboardList,
  faCog,
  faCopy,
  faIndustry,
  faAngleDown
);

class App extends Component {
  state = {
    previousPath: null,
    currentPath: null,
  };

  componentDidMount() {
    this.props.checkAuth(this.props.token);
  }

  componentDidUpdate(prevProps) {
    if (this.state.currentPath !== this.props.location.pathname) {
      this.setState({
        previousPath: this.state.currentPath,
        currentPath: this.props.location.pathname,
      });
    }

    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    ) {
      this.redirectAfterLogin();
    }
  }

  redirectAfterLogin() {
    const currentPath = this.state.currentPath || this.props.location.pathname;
    if (
      this.state.previousPath &&
      currentPath !== this.state.previousPath &&
      this.state.previousPath !== "/"
    ) {
      this.props.history.push(this.state.previousPath);
    } else if (currentPath.startsWith("/login") || currentPath === "/") {
      console.debug("Redirecting to /surveys");
      this.props.history.push("/surveys");
    }
  }

  authenticate({ username, password }) {
    this.props.authenticate({ username, password });
  }

  render() {
    if (this.props.loading) {
      return null;
    }

    return (
      <div>
        <Switch>
          <Route
            path="/login"
            render={props => (
              <Login
                {...props}
                onSubmit={userData => this.authenticate(userData)}
              />
            )}
          />
          {this.props.isAuthenticated && (
            <Route
              path={["/surveys", "/industries", "/forms"]}
              render={props => (
                <SurveyWrapperContainer
                  {...props}
                  isAuthenticated={this.props.isAuthenticated}
                />
              )}
            />
          )}
          {/* Catch-all (or 404) route, only if already logged in. */}
          {this.props.isAuthenticated && (
            <Route render={() => <Redirect to="/surveys" />} />
          )}

          <Redirect to="/login" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.authenticationReducer.isAuthenticated,
    token: state.authenticationReducer.token,
    loading: state.authenticationReducer.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkAuth: token => dispatch(authenticationActions.checkAuth(token)),
    authenticate: userData =>
      dispatch(authenticationActions.authenticate(userData)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
