import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchLeadForms,
  saveLeadForm,
} from "../../store/actions/leadform.actions";
import EditLeadForm from "./EditLeadForm";
import Spinner from "../../components/Spinner/Spinner";
import styles from "./LeadForm.module.css";
import Button from "../../components/UI/Button/Button/Button";
import { confirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";

class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formId: this.props.match.params.formId || "",
      leadForm: null,
      formInputs: {},
      hasChanged: false,
      error: null,
    };

    this.selectLeadForm = this.selectLeadForm.bind(this);
    this.onLeadFormEdit = this.onLeadFormEdit.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);

    this.props.fetchLeadForms();
  }

  componentDidUpdate(prevProps, prevState) {
    const { formId } = this.state;
    const didFormIdUpdate = prevState.formId !== formId;
    const didLeadFormUpdateFromNull =
      prevState.leadForm === null && this.state.leadForm !== null;
    const fetchedLeadForms =
      prevProps.leadForms.isFetching && !this.props.leadForms.isFetching;

    if (!didFormIdUpdate && !didLeadFormUpdateFromNull && !fetchedLeadForms) {
      return;
    }

    // Form ID is null or doesn't exist; reset the lead form & form iputs.
    if (!formId || !this.props.leadForms.allIds.includes(formId)) {
      this.setState({
        leadForm: null,
        formInputs: [],
        hasChanged: false,
        error: null,
      });
    }
    // Form ID has changed, the lead form hasn't been set or
    // finished fetching the lead forms; set the lead form & form inputs.
    else if (
      didFormIdUpdate ||
      this.state.leadForm === null ||
      fetchedLeadForms
    ) {
      const leadForm = this.props.leadForms.byId[formId];
      const formInputs = leadForm.formInputs.reduce((acc, cur) => {
        acc[cur] = this.props.leadForms.formInputs.byId[cur];
        return acc;
      }, {});

      this.setState({
        leadForm,
        formInputs,
        hasChanged: false,
        error: null,
      });
    }
  }

  saveLeadForm(onSuccess) {
    return this.props
      .saveLeadForm(this.state.leadForm, this.state.formInputs)
      .then(onSuccess, error => {
        this.setState({ error: error.response.data });
      });
  }

  onSaveClick() {
    this.saveLeadForm(() => this.setState({ hasChanged: false, error: null }));
  }

  changeToLeadForm(formId) {
    this.setState({ formId, hasChanged: false });
    this.props.history.push(`/forms/${formId}`);
  }

  selectLeadForm(event) {
    const formId = event.target.value;

    if (this.state.hasChanged) {
      confirmDialog({
        text: `Do you want to save the changes you made? Your changes will be lost if you don't save them.`,
        confirmText: "Save",
        extraText: "Don't save",
        onConfirm: () => this.saveLeadForm(() => this.changeToLeadForm(formId)),
        onExtra: () => this.changeToLeadForm(formId),
      });
    } else {
      this.changeToLeadForm(formId);
    }
  }

  onLeadFormEdit(propName, val, formInputId = null) {
    if (formInputId === null) {
      this.updateLeadForm(propName, val);
    } else {
      this.updateFormInput(propName, val, formInputId);
    }
  }

  updateLeadForm(propName, val) {
    let newState = { ...this.state.leadForm };
    newState[propName] = val;

    this.setState({
      leadForm: newState,
      hasChanged:
        this.state.hasChanged || this.state.leadForm[propName] !== val,
    });
  }

  updateFormInput(propName, val, id) {
    let newState = { ...this.state.formInputs };
    newState[id][propName] = val;

    this.setState({
      formInputs: newState,
      hasChanged:
        this.state.hasChanged || this.state.leadForm[propName] !== val,
    });
  }

  render() {
    // Get the lead forms on an array and sort them by title.
    const sortedLeadForms = Object.values(
      this.props.leadForms.byId
    ).sort((a, b) => a.title.localeCompare(b.title));

    return (
      <div className={styles.Container}>
        {(this.props.isFetching || this.props.isSaving) && (
          <div className={styles.LoadingOverlay}>
            <div className={styles.Spinner}>
              <Spinner />;
            </div>
          </div>
        )}
        <div className={styles.MenuBar}>
          <div>
            <label htmlFor="leadFormSelect">
              <h2 className={styles.MenuBarHeader}>Lead form:</h2>
            </label>
            <select
              onChange={this.selectLeadForm}
              value={this.state.formId}
              id="leadFormSelect"
              name="leadFormSelect"
            >
              <option value="">---</option>
              {sortedLeadForms.map(leadForm => (
                <option key={`${leadForm.id}`} value={leadForm.id}>
                  {`${leadForm.title} (${leadForm.languageCode})`}
                </option>
              ))}
            </select>
            <div className={styles.ErrorMessage}>
              {this.state.error !== null && (
                <span>Something went wrong while saving.</span>
              )}
            </div>
          </div>
          <div className={styles.SaveSettings}>
            <Button
              button="save"
              disabled={!this.state.hasChanged}
              onClick={this.onSaveClick}
            />
          </div>
        </div>
        {this.state.formId &&
        this.props.leadForms.allIds.includes(this.state.formId) ? (
          <EditLeadForm
            leadForm={this.state.leadForm}
            formInputs={this.state.formInputs}
            onChange={this.onLeadFormEdit}
          />
        ) : (
          <div className={styles.TextBox}>
            <h3>No lead form selected.</h3>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  leadForms: state.leadForms,
  isFetching: state.leadForms.isFetching,
  isSaving: state.leadForms.isSaving,
});

const mapDispatchToProps = dispatch => ({
  fetchLeadForms: formId => dispatch(fetchLeadForms(formId)),
  saveLeadForm: (leadForm, formInputs) =>
    dispatch(saveLeadForm(leadForm, formInputs)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeadForm)
);
