import React from "react";
import styles from "./AddButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddButton = (props) => {
  return (
    <div onClick={props.clicked} className={styles.AddButton}>
      <FontAwesomeIcon icon="plus" />
    </div>
  );
};

export default AddButton;
