import React, { Component } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import styles from "./ConfirmDialog.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

class ConfirmDialog extends Component {
  static propTypes = {
    text: PropTypes.string,
    confirmText: PropTypes.string,
    extraText: PropTypes.string,
    cancelText: PropTypes.string,
    onConfirm: PropTypes.func,
    onExtra: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    text: "",
    confirmText: "Yes",
    extraText: null,
    cancelText: "Cancel",
    onConfirm: () => {},
    onExtra: () => {},
    onCancel: () => {},
  };

  onConfirm = e => {
    this.props.onConfirm(e);
    this.close();
  };

  onExtra = e => {
    this.props.onExtra(e);
    this.close();
  };

  onCancel = e => {
    this.props.onCancel(e);
    this.close();
  };

  close = () => {
    removeElement();
  };

  render() {
    return (
      <div className={styles.Modal}>
        {
          <div className={styles.ModalContent}>
            <p className={styles.InfoText}>{this.props.text}</p>
            <div className={styles.ActionsWrapper}>
              <button className={styles.Actions} onClick={this.onConfirm}>
                {this.props.confirmText}
              </button>
              {this.props.extraText && (
                <button className={styles.Actions} onClick={this.onExtra}>
                  {this.props.extraText}
                </button>
              )}
              <button className={styles.Actions} onClick={this.onCancel}>
                {this.props.cancelText}
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmDialog)
);

const elementId = "os-confirm-dialog";

function removeElement() {
  const el = document.getElementById(elementId);
  unmountComponentAtNode(el);
  el.parentNode.removeChild(el);
}

export function confirmDialog(props) {
  let el = document.getElementById(elementId);

  if (!el) {
    el = document.createElement("div");
    el.id = elementId;
    document.body.appendChild(el);
  }

  render(<ConfirmDialog {...props} />, el);
}
