import instance from "../../axiosAPIInstances/axios-survey";

export const FETCH_REQUEST = "LEAD_FORM/FETCH_REQUEST";
export const FETCH_SUCCESS = "LEAD_FORM/FETCH_SUCCESS";
export const FETCH_ERROR = "LEAD_FORM/FETCH_ERROR";
export const SAVE_REQUEST = "LEAD_FORM/SAVE_REQUEST";
export const SAVE_SUCCESS = "LEAD_FORM/SAVE_SUCCESS";
export const SAVE_ERROR = "LEAD_FORM/SAVE_ERROR";

export const fetchLeadForms = (languageCode = null) => {
  return (dispatch, getState) => {
    const { leadForms } = getState();
    if (leadForms.allIds.length > 0) {
      return;
    }

    dispatch({
      type: FETCH_REQUEST,
    });

    instance.get(`/leadForm`, { params: { lang: languageCode } }).then(
      response =>
        dispatch({
          type: FETCH_SUCCESS,
          leadForms: response.data,
        }),
      error =>
        dispatch({
          type: FETCH_ERROR,
          error,
        })
    );
  };
};

export const saveLeadForm = (leadForm, formInputs) => {
  return dispatch => {
    dispatch({
      type: SAVE_REQUEST,
    });

    return instance
      .put(`/leadForm/${leadForm.id}`, {
        ...leadForm,
        formInputs: Object.values(formInputs),
      })
      .then(
        response =>
          dispatch({
            type: SAVE_SUCCESS,
            leadForm: response.data,
          }),
        error => {
          dispatch({
            type: SAVE_ERROR,
            error,
          });
          throw error;
        }
      );
  };
};
