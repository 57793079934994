import axios from "axios";

let baseURL = "";

switch (process.env.NODE_ENV) {
  case "production":
    baseURL = "https://api.onsagers.no/api"
    break;
  case "development":
    baseURL = "https://onsagers.isotammi.fi/api";
    break;
  default:
    baseURL = "https://onsagers.isotammi.fi/api";
}

const surveyInstance = axios.create({
  baseURL
});

export default surveyInstance;
