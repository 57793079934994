import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import CurrentURL from "./CurrentURL/CurrentURL";
import { connect } from "react-redux";
import Spinner from "../Spinner/Spinner";

class NavigationPath extends Component {
  render() {
    if (this.props.isFetching && !this.props.survey.id) {
      return <Spinner />;
    }

    return (
      <div>
        <Switch>
          <Route
            exact
            path="/surveys"
            render={props => (
              <CurrentURL
                {...props}
                loading={this.props.loading}
                label="home"
              />
            )}
          />
          <Route
            exact
            path="/industries"
            render={props => (
              <CurrentURL
                {...props}
                loading={this.props.loading}
                label="industries"
              />
            )}
          />
          <Route
            exact
            path="/login"
            render={props => (
              <CurrentURL
                {...props}
                loading={this.props.loading}
                label="login"
              />
            )}
          />
          <Route
            exact
            path="/surveys/doormat"
            render={props => (
              <CurrentURL
                {...props}
                loading={this.props.loading}
                label="home-link"
              />
            )}
          />
          {/* Addin new path */}
          <Route
            path="/surveys/:id/pages"
            render={props => (
              <CurrentURL
                {...props}
                label="layout"
                loading={this.props.loading}
                survey={this.props.survey}
              />
            )}
          />
          <Route
            path="/surveys/:id/layout"
            render={props => (
              <CurrentURL
                {...props}
                label="layout"
                loading={this.props.loading}
                survey={this.props.survey}
              />
            )}
          />
          <Route
            path="/surveys/:id/questions"
            render={props => (
              <CurrentURL
                {...props}
                label="questions"
                loading={this.props.loading}
                survey={this.props.survey}
              />
            )}
          />
          <Route
            path="/surveys/:id/settings"
            render={props => (
              <CurrentURL
                {...props}
                label="settings"
                loading={this.props.loading}
                survey={this.props.survey}
              />
            )}
          />
          <Route
            path="/surveys/:id/submissions"
            render={props => (
              <CurrentURL
                {...props}
                label="lastSubmissions"
                loading={this.props.loading}
                survey={this.props.survey}
              />
            )}
          />
          <Route
            path="/surveys/:id"
            render={props => (
              <CurrentURL
                {...props}
                label="chosenSurvey"
                loading={this.props.loading}
                surveyName={this.props.survey.name}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    survey: state.surveyReducer.currentSurvey,
    isFetching: state.surveyReducer.isFetching,
    loading: state.surveyReducer.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationPath)
);
