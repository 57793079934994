import * as actionTypes from "./actionTypes";
import axios from "../../axiosAPIInstances/axios-auth";

//Login actions
export const loginSuccess = token => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
  };
};

export const loginFail = error => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error,
  };
};

export const logoutRequest = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

export const authenticate = userData => {
  // let url = "https://onsagers.isotammi.fi/api-token-auth/";
  let data = {
    username: userData.username,
    password: userData.password,
  };

  return dispatch => {
    axios
      .post("api-token-auth/", data)
      .then(function(response) {
        // handle success
        if (response.data.token) {
          dispatch(loginSuccess(response.data.token));
        } else {
          dispatch(loginFail("Error"));
        }
        console.log(response);
      })
      .catch(function(error) {
        dispatch(loginFail(error));
      });
  };
};
// export const authenticate = userData => {
//   let url = "https://api.onsagers.no/new/session";
//   // let url = "https://onsagers.isotammi.fi/api/session";
//   let data = {
//     emailAddress: userData.username,
//     password: userData.password,
//   };
//   return dispatch => {
//     // dispatch(startFetching());
//     axios({
//       method: "post",
//       url: url,
//       data: data,
//       withCredentials: true,
//     })
//       .then(function(response) {
//         // handle success
//         if (response.data.logged) {
//           dispatch(loginRequest(response.data.logged));
//           dispatch(loginSucess());
//         } else {
//           dispatch(loginFail(response.data.error));
//         }
//         console.log(response);
//         // dispatch(loginRequest(response.data));
//       })
//       .catch(function(error) {
//         dispatch(loginFail(error));
//       });
//   };
// };

export const logout = () => {
  return dispatch => {
    console.log("Logged out");
    dispatch(logoutRequest());
  };
};

export const checkAuth = token => {
  return dispatch => {
    console.debug("Validating the API token...");
    axios
      .get("api-token-validate/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then(response => {
        console.log("checkAuth", response);
        if (response.data === "OK") {
          console.debug("API token was valid.");
          dispatch(checkAuthRequest(true));
        } else {
          console.debug(
            "Something went horribly wrong with API token validation."
          );
          dispatch(checkAuthRequest(false));
        }
      })
      .catch(error => {
        console.debug("API token was invalid.");
        dispatch(checkAuthRequest(false));
      });
  };
};
// export const checkAuth = () => {
//   return dispatch => {
//     console.log("checking auth....");
//     axios
//       .get("https://api.onsagers.no/new/session", { withCredentials: true })
//       .then(response => {
//         console.log("checkAuth", response);
//         if (response.data.logged) {
//           dispatch(checkAuthRequest(true));
//         } else {
//           dispatch(checkAuthRequest(false));
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };
// };

export const checkAuthRequest = isAuthenticated => {
  return {
    type: actionTypes.CHECK_AUTH,
    authenticated: isAuthenticated,
  };
};
