import * as actionTypes from "./actionTypes";
import axios from "../../axiosAPIInstances/axios-survey";

export const startFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_START,
  };
};
export const endFetching = () => {
  return {
    type: actionTypes.FETCHING_DATA_END,
  };
};

export const fetchDoormatFail = () => {
  return {
    type: actionTypes.FETCH_DOORMAT_FAIL,
  };
};
export const getDoormatData = data => {
  return {
    type: actionTypes.GET_DOORMAT_DATA,
    data: data,
  };
};

export const updateDormatData = data => {
  return {
    type: actionTypes.UPDATED_DOORMAT,
    data: data,
  };
};

export const refreshComponent = () => {
  return {
    type: actionTypes.REFRESH_DORMAT,
  };
};

export const getDoormat = () => {
  console.log("loasdou");
  return dispatch => {
    dispatch(startFetching());
    axios
      .get("/textValues/list", { withCredentials: true })
      .then(response => {
        console.log(response.data);
        // console.log(response.data);
        let doormatData = response.data.filter((item, index) => {
          return item.name === "Doormat";
        });
        // console.log(getDoormatData);
        dispatch(getDoormatData(doormatData[0]));
      })
      .then(data => {
        dispatch(endFetching());
      })
      .catch(error => {
        dispatch(fetchDoormatFail(error));
        console.log(error);
      });
  };
};

export const saveDoormat = (id, updatedData) => {
  return dispatch => {
    dispatch(startFetching());

    axios
      .put(`textValues/${id}`, updatedData)
      .then(response => {
        axios
          .get("/textValues/list", { withCredentials: true })
          .then(response => {
            // console.log(response.data);
            let doormatData = response.data.filter((item, index) => {
              return item.name === "Doormat";
            });
            // console.log(getDoormatData);
            dispatch(updateDormatData(doormatData[0]));
          })
          .then(data => {
            dispatch(endFetching());
          })
          .catch(error => {
            dispatch(fetchDoormatFail(error));
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
  };
};
