const imageSet = [
  {
    category: "Archipelago",
    name: "Default (yellow)",
    url:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_yellow_BG_2560_2560_4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_yellow_BG_thumb_4.jpg",
  },
  {
    category: "Archipelago",
    name: "Default (red)",
    url:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_red_BG_2560_2560_2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_red_BG_thumb_2.jpg",
  },
  {
    category: "Archipelago",
    name: "Default (green)",
    url:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_green_BG_2560_2560_1.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_green_BG_thumb_1.jpg",
  },
  {
    category: "Archipelago",
    name: "Default (blue)",
    url:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_blue_BG_2560_2560_3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/ONSAGER_blue_BG_thumb_3.jpg",
  },
  {
    category: "Machines",
    name: "Blue",
    url: "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560_small.jpg",
  },
  {
    category: "Machines",
    name: "Yellow",
    url: "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-2_small.jpg",
  },
  {
    category: "Machines",
    name: "Yellow-2",
    url: "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-3_small.jpg",
  },
  {
    category: "Machines",
    name: "Green",
    url: "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-4_small.jpg",
  },
  {
    category: "Machines",
    name: "Red",
    url: "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-5.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers-2560x2560-5_small.jpg",
  },
  {
    category: "Architecture",
    name: "1",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px.jpg",
  },
  {
    category: "Architecture",
    name: "2",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px2.jpg",
  },
  {
    category: "Architecture",
    name: "3",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px3.jpg",
  },
  {
    category: "Architecture",
    name: "4",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px4.jpg",
  },
  {
    category: "Architecture",
    name: "5",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px5.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px5.jpg",
  },
  {
    category: "Architecture",
    name: "6",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px6.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Arkitektur_2560x1440px6.jpg",
  },
  {
    category: "Green",
    name: "1",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px.jpg",
  },
  {
    category: "Green",
    name: "2",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px2.jpg",
  },
  {
    category: "Green",
    name: "3",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px3.jpg",
  },
  {
    category: "Green",
    name: "4",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Green_2560x1440px4.jpg",
  },
  {
    category: "Landscape",
    name: "1",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px.jpg",
  },
  {
    category: "Landscape",
    name: "2",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px2.jpg",
  },
  {
    category: "Landscape",
    name: "3",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px3.jpg",
  },
  {
    category: "Landscape",
    name: "4",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px4.jpg",
  },
  {
    category: "Landscape",
    name: "5",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px5.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px5.jpg",
  },
  {
    category: "Landscape",
    name: "6",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px6.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Landscape_2560x1440px6.jpg",
  },
  {
    category: "Yellow",
    name: "1",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px.jpg",
  },
  {
    category: "Yellow",
    name: "2",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px2.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px2.jpg",
  },
  {
    category: "Yellow",
    name: "3",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px3.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px3.jpg",
  },
  {
    category: "Yellow",
    name: "4",
    url:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px4.jpg",
    thumbnail:
      "https://ipsjekk.onsagers.no/static/media/Onsagers_IP-sjekk_Bakgrunn_Yellow_2560x1440px4.jpg",
  },
];

export default imageSet;
