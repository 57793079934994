import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as submissionsActions from "../../store/actions/submissions";
import styles from "./Submission.module.css";
import Button from "../../components/UI/Button/Button/Button";

class Submission extends Component {
  onEditSubmissionDetails() {
    this.props.history.push(`${this.props.match.url}/${this.props.id}`);
  }

  clickDeleteSubmission() {
    this.props.onWantToDeleteSubmission(this.props.index, this.props.id);
  }

  render() {
    const editAndDeleteSubmission = (
      <div className={styles.ButtonsContainer}>
        <div
          onClick={() => this.onEditSubmissionDetails()}
          className={styles.Button}
        >
          <Button button="list" />
        </div>
        <div
          onClick={this.clickDeleteSubmission.bind(this)}
          className={styles.Button}
        >
          <Button button="delete" />
        </div>
      </div>
    );

    // TODO Maybe right align all the numbers?
    return (
      <React.Fragment>
        <div className={styles.TableContent}>
          <div className={`${styles.Content} ${styles.Small}`}>
            {this.props.date.slice(0, 10)}
          </div>
          <div className={`${styles.Content} ${styles.Medium}`}>
            {this.props.email}
          </div>
          <div className={`${styles.Content} ${styles.Medium}`}>
            {this.props.companyName}
          </div>
          <div className={`${styles.Content} ${styles.Small}`}>
            {Math.round(this.props.progress * 100)}%
          </div>
          <div className={`${styles.Content} ${styles.Small}`}>
            {this.props.progress >= 1 ? `${this.props.score}%` : "-"}
          </div>
          <div className={`${styles.Content} ${styles.Small}`}>
            {editAndDeleteSubmission}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onWantToDeleteSubmission: (index, submissionId) =>
      dispatch(submissionsActions.wantToDeleteSubmission(index, submissionId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Submission)
);
