import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import styles from "./ImagePicker.module.css";
import imageSet from "./assets/imageSet";
import PropTypes from "prop-types";

class ImagePicker extends Component {
  state = {
    index: 0,
    image: "https://via.placeholder.com/220",
    category: "",
    name: "",
    thumbnail: "",
  };

  componentDidMount() {
    const { image, url } = this.props;
    this.setState({
      index: this.getImages(image.category).findIndex(el => el.url === url),
      image: url,
      category: image.category,
      name: image.name,
      thumbnail: image.thumbnail,
    });
  }

  componentDidUpdate(prevProps) {
    const { image, url } = this.props;
    if (prevProps.url !== url) {
      this.setState({
        index: this.getImages(image.category).findIndex(el => el.url === url),
        image: url,
        category: image.category,
        name: image.name,
        thumbnail: image.thumbnail,
      });
    }
  }

  categoryChange = e => {
    this.setState({ category: e.target.value });
  };

  imageChange = e => {
    const index = e.target.value;
    const image = this.getImage(index);

    this.setState({ index, ...image });
    this.props.onImageChange(image.url);
  };

  getImages = category => {
    category = category || this.state.category;
    return imageSet.filter(image => image.category === category);
  };

  getImage = index => {
    return this.getImages()[index];
  };

  render() {
    const bgCategory = Array.from(
      new Set(imageSet.map(({ category }) => category))
    );

    const bgImages = this.getImages();
    const textBackgroundColor = this.props.textBackgroundColor.concat("99");

    const sampleText = (
      <p className={styles.PreviewText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
      </p>
    );

    return (
      <div>
        <div className={styles.ImagePicker}>
          <div className={styles.ImagePicker_selector}>
            <label className={styles.Selector_category}>
              Category:
              <select
                value={this.state.category}
                onChange={this.categoryChange}
              >
                {bgCategory.map((category, key) => {
                  return (
                    <option key={`${category}_${key}`} value={category}>
                      {category}
                    </option>
                  );
                })}
              </select>
            </label>
            <label className={styles.Selector_image}>
              Image:
              <select value={this.state.index} onChange={this.imageChange}>
                {bgImages.map((image, index) => {
                  return (
                    <option key={`${image.name}_${index}`} value={index}>
                      {image.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className={styles.ImagePicker_thumbnail}>
            <p>Preview:</p>
            <div
              className={styles.Thumbnail_image}
              style={{ backgroundImage: `url(${this.state.thumbnail})` }}
            >
              <div
                className={styles.textBackgroundColor}
                style={{ backgroundColor: textBackgroundColor }}
              >
                {sampleText}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImagePicker.propTypes = {
  onImageChange: PropTypes.func,
  image: PropTypes.object,
  url: PropTypes.string, // Do we need this if we have "image" already?
  textBackgroundColor: PropTypes.string,
};

const mapStateToProps = state => {
  return { activeIndex: state.surveyReducer.activeIndex };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImagePicker)
);
