// TODO Not in use at the moment
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as doormatActions from "../../store/actions/doormat";
// import EditorModule from "../EditorModule/EditorModule";
import Spinner from "../../components/Spinner/Spinner";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import styles from "./Doormat.module.css";
import Button from "../../components/UI/Button/Button/Button";

class Doormat extends Component {
  state = {
    moduleContent: "",
  };
  //rich tech editor styles
  toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"], // toggled buttons
    [{ list: "ordered" }, { list: "bullet" }], // lists
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ color: [] }], // dropdown with defaults from theme
    [{ align: [] }], // text aligning
    ["clean"], // remove formatting button
  ];
  modules = {
    toolbar: this.toolbarOptions,
  };
  richTextEditorChange = value => {
    this.setState({ moduleContent: value });
  };
  saveDoormat = () => {
    //send to redux the updated state from rich text editor
    let updatedModule = {
      value: this.state.moduleContent,
    };
    console.log("Dootmat props", this.props.doormatData.id);
    console.log("updated module", updatedModule);
    this.props.onSaveDoormat(this.props.doormatData.id, updatedModule);
  };

  componentDidMount() {
    console.log("Doormat props", this.props);
    this.props.onGetDoormat();
  }

  componentDidUpdate(prevProps) {
    if (this.props.doormatData.value !== prevProps.doormatData.value) {
      this.setState({
        moduleContent: this.props.doormatData.value,
      });
    }
    if (prevProps.doormatData !== this.props.doormatData) {
      //     console.log(prevProps.doormatData);
      //   console.log(this.props.doormatData);
      //console.log("dormat data changes");
      this.setState({
        moduleContent: this.props.doormatData.value,
      });
    }
  }

  //use this function to init doormat field on DB
  // initDoormat = (event) => {
  //   event.preventDefault();
  //   axios.post("/textValues", {"value" : 'Sample text', "name" : 'Doormat'})
  //   .then(response => {
  //     console.log(response);
  //   })
  //   .catch(error =>{
  //     console.log(error);
  //   })
  // }

  render() {
    console.log("state", this.state);
    console.log("props", this.props);
    return (
      <div>
        {this.props.loading ? (
          <Spinner />
        ) : this.props.doormatData ? (
          <div className={styles.EditorModuleWrapper}>
            <div>
              <p className={styles.DoormatTitle}>Doormat page content</p>
              <div className={styles.SaveButton} onClick={this.saveDoormat}>
                <Button button="save" />
              </div>
            </div>
            <ReactQuill
              modules={this.modules}
              value={this.state.moduleContent}
              onChange={this.richTextEditorChange}
            />
          </div>
        ) : null}
      </div>

      //user for initin doormat object
      // <div><button onClick={(event) => this.initDoormat(event)}>init doormat</button></div>
    );
  }
}

//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    doormatData: state.doormatReducer.doormatData,
    loading: state.doormatReducer.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetDoormat: () => dispatch(doormatActions.getDoormat()),
    onSaveDoormat: (id, updatedData) =>
      dispatch(doormatActions.saveDoormat(id, updatedData)),
    onRefreshComponent: () => dispatch(doormatActions.refreshComponent()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Doormat)
);
