import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/actions";
import Section from "../../components/UI/Section/Section";
import styles from "./EditLayout.module.css";
import EditorModulesList from "../EditorModulesList/EditorModulesList";
import Spinner from "../../components/Spinner/Spinner";

class EditLayout extends Component {
  state = {
    activePage: 1
  };

  componentDidMount() {
    //get the survey id
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
  }

  //get current section
  onActiveSection(index, sectionId) {
    this.props.onGetIndex(index, sectionId);
    // Sets the state of which Page is active
    // to be passed as prop to EditorModule
    this.setState({ activePage: index });
  }

  render() {
    // Static array for side navidation in pages tab
    let pages = [
      {
        name: "Model Page"
      },
      {
        name: "Result Page"
      },
      {
        name: "Sidebar"
      }
    ];
    let getPage = pages.map((section, index) => {
      return (
        // Pages component is used to render the side nav in "Pages" and "Questions"
        <Section
          clicked={() => this.onActiveSection(index, section.id)}
          className={
            this.props.activeIndex.index === index
              ? styles.Active
              : styles.Section
          }
          id={section.id}
          key={index}
          sectionName={section.name}
        />
      );
    });

    return (
      <div className={styles.EditQuestionsWrapper}>
        <div className={styles.SectionsWrapper}>{getPage}</div>
        <div className={styles.QuestionsWrapper}>
          {this.props.loading === true ? (
            <div className={styles.QuestionListSpinner}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.ViewWrapper}>
              <EditorModulesList
                activePage={this.state.activePage} // used for conditional rendering of the modules in moduleList
                index={this.props.activeIndex.index}
                sections={this.props.currentSection}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeIndex: state.surveyReducer.activeIndex,
  survey: state.surveyReducer.currentSurvey,
  currentSection: state.surveyReducer.currentSection,
  loading: state.surveyReducer.loading
});

const mapDispatchToProps = dispatch => {
  return {
    fetchSurveyIfNeeded: surveyId =>
      dispatch(actions.fetchSurveyIfNeeded(surveyId)),
    onGetIndex: (index, sectionId) =>
      dispatch(actions.getIndex(index, sectionId))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditLayout)
);
