import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';

export class EditIndividualQuestiion extends Component {


  render() {
    return (
      <div>
        <p>open up the question here</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditIndividualQuestiion));
