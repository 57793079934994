import * as actionType from "../actions/actionTypes";

const initialState = {
  token: sessionStorage.getItem("TOKEN"),
  isAuthenticated: null,
  error: "",
  loading: true,
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_SUCCESS:
      console.debug("Received token ", action.token)

      return {
        ...state,
        token: action.token,
        isAuthenticated: true,
      };

    case actionType.LOGIN_FAIL:
      return {
        ...state,
        error: action.error,
        isAuthenticated: false,
      };

    case actionType.LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };

    case actionType.CHECK_AUTH:
      let token = null;

      if (action.authenticated) {
        token = state.token;
      }

      return {
        ...state,
        token: token,
        isAuthenticated: action.authenticated ? true : false,
        loading: false,
      };

    default:
      return state;
  }
};
export default authenticationReducer;
