import React from "react";
import styles from "./FieldInstructions.module.css";
import PropTypes from "prop-types";

const EditorInfoImage = props => {
  return (
    <div className={props.isVisible ? styles.ShowInfo : styles.HideInfo}>
      <span className={styles.Instructions}> {props.content}</span>
    </div>
  );
};

EditorInfoImage.propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.string,
};

export default EditorInfoImage;
