import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/Onsagers-logo.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// components
import styles from "./Navigationbar.module.css";
import UserBar from "../UserBar/UserBar";
import NavigationPath from "./NavigationPath";
import * as authenticationActions from "../../store/actions/authentication";

class NavigationBar extends Component {
  logout = () => {
    this.props.onLogout();
  };

  render() {
    return (
      <div className={styles.Navigationbar}>
        <div className={`${styles.logo} ${styles.first}`}>
          <Link to={`/surveys`}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className={`${styles.second}`}>
          <NavigationPath />
        </div>
        <div className={` ${styles.third}`}>
          <UserBar
            hideDoormat={true}
            logout={this.logout}
            surveyId={this.props.survey ? this.props.survey.id : null}
            industryList={
              this.props.survey ? this.props.survey.industries : null
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    doormatIsVisible: state.doormatReducer.doormat,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(authenticationActions.logout()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationBar)
);
