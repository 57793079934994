import React from "react";
import styles from "./SettingsInput.module.css";
import Button from "../Button/Button/Button";
import FieldInstructions from "./FieldInstructions/FieldInstructions";
import PropTypes from "prop-types";

const SettingsInput = props => {
  let input = "";
  switch (props.type) {
    case "text":
      input = (
        <div className={styles.Group}>
          {props.isLeadForm ? (
            <div className={styles.CheckBoxesWrapper}>
              <div className={styles.CheckBoxColumn}>
                <label htmlFor={props.htmlName}>Visible</label>
                <input
                  name={props.htmlName}
                  onChange={props.changeVisibility}
                  defaultChecked={props.isChecked}
                  className={styles.ShowToSurvey}
                  type="checkbox"
                />
              </div>
              <div className={styles.CheckBoxColumn}>
                <label htmlFor={props.htmlName}>Mandatory</label>
                <input
                  name={props.htmlName}
                  onChange={props.changeMandatory}
                  defaultChecked={props.isRequired}
                  className={styles.ShowToSurvey}
                  type="checkbox"
                />
              </div>
              <div className={styles.ErrorMessageColumn}>
                <label htmlFor={props.htmlName}>Error Message</label>
                <input
                  name={props.errorMessageName}
                  value={props.errorMessage}
                  onChange={props.errorMessageHandler}
                  className={` ${styles.ErrorMessageInput}`}
                  type="text"
                />
              </div>
            </div>
          ) : null}
          <div className={styles.CheckBoxesWrapper}>
            <div className={styles.InputLabel}>
              <label htmlFor={props.htmlName}>{props.label}</label>
              <input
                className={styles.SettingsInput}
                name={props.htmlName}
                type="text"
                onChange={props.changeState}
                value={props.value}
              />
            </div>
            <div
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
            >
              <Button button="info" />
            </div>
            <FieldInstructions
              isVisible={props.showInstructions}
              content={props.helpText}
              name={props.htmlName}
            />
          </div>
        </div>
      );
      break;
    case "select":
      input = (
        <div className={styles.Group}>
          <div className={styles.InputLabel}>
            <label htmlFor={props.htmlName}>{props.label}</label>

            <select
              className={styles.SettingsInput}
              value={props.value}
              name={props.htmlName}
              onChange={props.changeState}
            >
              {props.industries}
            </select>
          </div>
          <div
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            <Button button="info" />
          </div>
          <FieldInstructions
            isVisible={props.showInstructions}
            content={props.helpText}
            name={props.htmlName}
          />
        </div>
      );
      break;
    case "editor":
      input = (
        <div className={styles.Editor}>
          <div className={styles.CheckBoxesWrapper}>
            <div className={styles.CheckBoxColumn}>
              <label htmlFor={props.htmlName}>Visible</label>
              <input
                name={props.htmlName}
                onChange={props.changeVisibility}
                defaultChecked={props.isChecked}
                className={styles.ShowToSurvey}
                type="checkbox"
              />
            </div>
            <div className={styles.CheckBoxColumn}>
              <label htmlFor={props.htmlName}>Mandatory</label>
              <input
                name={props.htmlName}
                onChange={props.changeMandatory}
                defaultChecked={props.isRequired}
                className={styles.ShowToSurvey}
                type="checkbox"
              />
            </div>
          </div>
        </div>
      );
      break;
    // New case for publish survey feature
    case "publish":
      input = (
        <div className={styles.Publish}>
          <div className={styles.CheckBoxesWrapper}>
            <label htmlFor={`${props.htmlName}_${props.id}`}>
              Publish Survey
            </label>
            <input
              id={`${props.htmlName}_${props.id}`}
              name={props.htmlName}
              onChange={props.changeState}
              defaultChecked={props.isChecked}
              className={styles.ShowToSurvey}
              type="checkbox"
            />
          </div>
          <div className={styles.Group}>
            <div
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
            >
              <Button button="info" />
            </div>
            <FieldInstructions
              isVisible={props.showInstructions}
              content={props.helpText}
              name={props.htmlName}
            />
          </div>
        </div>
      );
      break;
    case "editorInstructions":
      input = (
        <div className={styles.Group}>
          <div
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            <Button button="info" />
          </div>
          <FieldInstructions
            isVisible={props.showInstructions}
            content={props.helpText}
            name={props.htmlName}
          />
        </div>
      );
      break;
    default:
      break;
  }
  return <div className={styles.Row}>{input}</div>;
};

SettingsInput.propTypes = {
  showInstructions: PropTypes.bool,
  helpText: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  type: PropTypes.string.isRequired,
  htmlName: PropTypes.string,
  label: PropTypes.string,
  isLeadForm: PropTypes.bool,
};

export default SettingsInput;
