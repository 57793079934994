import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as api from "../../api";
import * as actions from "../../store/actions/actions";
import styles from "./Industries.module.css";
import Spinner from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UNASSIGNED_SURVEY = "__UNASSIGNED__";

class Industries extends Component {
  state = {
    industries: [],
    updatedIndustries: {},
  };

  componentDidMount() {
    this.getIndustries();
  }

  getIndustries() {
    this.props.startFetching();
    api
      .getIndustries()
      .then(response => {
        this.setState({ industries: response.data });
      })
      .finally(() => {
        this.props.endFetching();
      });
  }

  saveIndustries = () => {
    // Generate payload to be sent to backend
    // Necessary to change from dictionary format to array of objects
    let payload = [];
    for (let industryId in this.state.updatedIndustries) {
      const surveyId = this.state.updatedIndustries[industryId];
      const item = {
        id: industryId,
        survey: surveyId,
      };
      payload.push(item);
    }

    this.props.startSaving();
    api.saveIndustries(payload).finally(() => {
      this.props.endSaving();
      // It was necessary to invalidate the survey due to local implementation
      // of industry state instead of redux update
      this.props.invalidateSurvey();
    });
  };

  onSurveyChange = (e, industryId) => {
    e.persist();
    let surveyId = e.target.value;

    // If survey is unassigned, set survey ID to null
    if (surveyId === UNASSIGNED_SURVEY) {
      surveyId = null;
    }

    // Update the Industries array survey id
    this.setState(prevState => {
      let newState = { ...prevState };
      let industry = newState.industries.find(el => el.id === industryId);
      industry.survey = surveyId;
      return newState;
    });

    // Update dictionary entried in the state for edited industries
    this.setState(prevState => {
      let newState = { ...prevState };
      let updatedIndustries = newState.updatedIndustries;
      updatedIndustries[industryId] = surveyId;
      return newState;
    });
  };

  render() {
    if (this.props.isFetching || this.props.isSaving) {
      return (
        <div className={styles.Industries}>
          <Spinner />
        </div>
      );
    }

    const { surveys } = this.props;
    const industry = this.state.industries.map((industry, index) => (
      <div className={styles.Industry} key={`${index}_Industry`}>
        <div className={styles.IndustriesColumn}>{industry.name}</div>
        <div> &rang;&rang; </div>
        <div className={styles.SurveysColumn}>
          <select
            value={industry.survey || ""}
            onChange={e => this.onSurveyChange(e, industry.id)}
          >
            <option value={UNASSIGNED_SURVEY}>{"-- Unassigned --"}</option>
            {surveys &&
              surveys.map(survey => (
                <option value={survey.id} key={survey.id}>
                  {survey.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    ));

    return (
      <div className={styles.IndustriesWrapper}>
        <h2 className={styles.IndustriesHeading}>
          Assign Surveys to Industries
        </h2>
        <div className={styles.IndustriesList}>{industry}</div>
        <div className={styles.SaveIndustries} onClick={this.saveIndustries}>
          <FontAwesomeIcon style={{ fontSize: "26px" }} icon="save" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    surveys: state.surveyReducer.allSurveys,
    isFetching: state.surveyReducer.isFetching,
    isSaving: state.surveyReducer.isSaving,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startFetching: () => dispatch(actions.startFetching()),
    endFetching: () => dispatch(actions.endFetching()),
    startSaving: () => dispatch(actions.startSaving()),
    endSaving: () => dispatch(actions.endSaving()),
    invalidateSurvey: () => dispatch(actions.invalidateSurvey()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Industries)
);
