import * as actionTypes from "../actions/actionTypes";
import produce from "immer";

const initialState = {
  activeIndex: {
    index: 0,
    id: "",
  },
  allSurveys: [],
  currentSurvey: {},
  currentSection: {},
  currentOption: null,
  wantToEditOption: false,
  loading: false,
  isSaving: false,
  surveyLanguage: "English", // TODO Doesn't belong here
  isFetching: false,
  didInvalidate: false,
};

const findSection = (survey, sectionId) => {
  const index = survey.sections.findIndex(section => {
    return section.id === sectionId;
  });
  return {
    index,
    data: survey.sections[index],
  };
};

const findQuestion = (section, questionId) => {
  const index = section.questions.findIndex(question => {
    return question.id === questionId;
  });
  return {
    index,
    data: section.questions[index],
  };
};

const findAnswerOption = (question, answerOptionId) => {
  const index = question.answerOptions.findIndex(answerOption => {
    return answerOption.id === answerOptionId;
  });
  return {
    index,
    data: index >= 0 ? question.answerOptions[index] : null,
  };
};

const findAnswerOptionInSurvey = (survey, answerOptionId) => {
  for (const section of survey.sections) {
    for (const question of section.questions) {
      const answerOption = findAnswerOption(question, answerOptionId);
      if (answerOption.data) {
        return {
          ...answerOption,
          question,
        };
      }
    }
  }
};

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      //survey
      case actionTypes.INVALIDATE_SURVEY:
        draft.didInvalidate = true;
        break;

      case actionTypes.REQUEST_SURVEY:
        draft.loading = true;
        draft.currentSurvey = {};
        draft.isFetching = true;
        draft.didInvalidate = false;
        break;

      case actionTypes.RECEIVE_SURVEY:
        draft.loading = false;
        draft.currentSurvey = action.currentSurvey;
        draft.isFetching = false;
        draft.didInvalidate = false;
        break;

      case actionTypes.START_SAVING:
        draft.isSaving = true;
        break;

      case actionTypes.END_SAVING:
        draft.isSaving = false;
        break;

      case actionTypes.SET_SURVEY_LANGUAGE:
        draft.surveyLanguage = action.language;
        break;

      case actionTypes.ADD_SURVEY:
        draft.loading = false;
        draft.allSurveys = [...state.allSurveys, action.survey];
        break;

      case actionTypes.DELETE_SURVEY: // Deleting survey from survey list
        draft.loading = false;
        draft.allSurveys = state.allSurveys.filter(
          survey => survey.id !== action.id
        );
        break;

      case actionTypes.FETCHING_DATA_START:
        draft.loading = true;
        draft.isFetching = true;
        break;

      case actionTypes.FETCHING_DATA_END:
        draft.loading = false;
        draft.isFetching = false;
        break;

      case actionTypes.SET_SURVEYS:
        draft.loading = false;
        draft.allSurveys = action.surveys;
        break;

      case actionTypes.SET_ACTIVE_INDEX:
        draft.activeIndex = {
          index: action.index,
          id: action.sectionId,
        };
        break;

      case actionTypes.SET_CURRENT_OPTION: //for editing option
        draft.wantToEditOption = true;
        draft.currentOption = action.option;
        break;

      case actionTypes.CLOSE_MODAL:
        draft.wantToEditOption = false;
        break;

      case actionTypes.ADD_QUESTION:
        let section = findSection(draft.currentSurvey, action.question.section);
        draft.currentSurvey.sections[section.index].questions.splice(
          0,
          0,
          action.question
        );
        break;

      case actionTypes.UPDATE_QUESTION:
        section = findSection(draft.currentSurvey, action.question.section);
        let question = findQuestion(section.data, action.question.id);
        draft.currentSurvey.sections[section.index].questions[question.index] =
          action.question;
        break;

      case actionTypes.DELETE_QUESTION:
        section = findSection(draft.currentSurvey, action.sectionId);
        question = findQuestion(section.data, action.questionId);
        draft.currentSurvey.sections[section.index].questions.splice(
          question.index,
          1
        );
        break;

      case actionTypes.ADD_ANSWER_OPTION:
        section = findSection(draft.currentSurvey, action.sectionId);
        question = findQuestion(section.data, action.questionId);
        question.data.answerOptions.splice(0, 0, action.answerOption);
        break;

      case actionTypes.UPDATE_ANSWER_OPTION:
        let answerOption = findAnswerOptionInSurvey(
          draft.currentSurvey,
          action.answerOption.id
        );
        answerOption.question.answerOptions[answerOption.index] =
          action.answerOption;
        break;

      case actionTypes.DELETE_ANSWER_OPTION:
        section = findSection(draft.currentSurvey, action.sectionId);
        question = findQuestion(section.data, action.questionId);
        answerOption = findAnswerOption(question.data, action.answerOptionId);
        question.data.answerOptions.splice(answerOption.index, 1);
        break;

      case actionTypes.UPDATE_SECTION_SETTINGS:
        section = findSection(draft.currentSurvey, action.sectionId);
        draft.currentSurvey.sections[section.index] = {
          ...section.data,
          ...action.data,
        };
        break;

      default:
        break;
    }
  });
};

export default reducer;
