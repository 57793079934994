import {
  FETCH_ERROR,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  SAVE_ERROR,
  SAVE_REQUEST,
  SAVE_SUCCESS,
} from "../actions/leadform.actions";
import produce from "immer";

const initialState = {
  allIds: [],
  byId: {},
  isFetching: false,
  isSaving: false,
  formInputs: {
    allIds: [],
    byId: {},
  },
};

function upsertLeadForms(draft, action) {
  const { leadForms } = action;
  for (const leadForm of leadForms) {
    upsertLeadForm(draft, leadForm);
  }
}

function upsertLeadForm(draft, leadForm) {
  draft.byId[leadForm.id] = leadForm;

  let formInputs = [];
  for (const formInput of leadForm.formInputs) {
    formInputs.push(formInput.id);
    draft.formInputs.byId[formInput.id] = formInput;
  }
  draft.byId[leadForm.id].formInputs = formInputs;

  draft.allIds = Object.keys(draft.byId);
  draft.formInputs.allIds = Object.keys(draft.formInputs.byId);
}

const reducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_REQUEST:
        draft.isFetching = true;
        break;
      case FETCH_SUCCESS:
        upsertLeadForms(draft, action);
        draft.isFetching = false;
        break;
      case FETCH_ERROR:
        // TODO Handle error somehow?
        draft.isFetching = false;
        break;
      case SAVE_REQUEST:
        draft.isSaving = true;
        break;
      case SAVE_SUCCESS:
        upsertLeadForm(draft, action.leadForm);
        draft.isSaving = false;
        break;
      case SAVE_ERROR:
        draft.isSaving = false;
        break;
      default:
        break;
    }
  });
};

export default reducer;
