import React from "react";
import styles from "./SubmissionHeaders.module.css";
const SubmissionHeaders = () => {
  return (
    <div className={styles.TableHeaders}>
      <div className={`${styles.Header} ${styles.Small}`}>Date</div>
      <div className={`${styles.Header} ${styles.Medium}`}>Lead Email</div>
      <div className={`${styles.Header} ${styles.Medium}`}>Company Name</div>
      <div className={`${styles.Header} ${styles.Small}`}>Progress</div>
      <div className={`${styles.Header} ${styles.Small}`}>Score</div>
      <div className={`${styles.Header} ${styles.Small}`}>Actions</div>
    </div>
  );
};

export default SubmissionHeaders;
