import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";
import styles from "./IndividualSurveyOptions.module.css";
import Spinner from "../Spinner/Spinner";

class IndividualSurvey extends Component {
  componentDidMount() {
    // TODO This belongs in the container
    this.props.fetchSurveyIfNeeded(this.props.match.params.id);
  }

  render() {
    if (this.isFetching && !this.survey.id) {
      return <Spinner />;
    }
    return (
      <div className={styles.Wrapper}>
        <div className={styles.SectionWrapper}>
          <div className={styles.Section}>
            <Link to={`${this.props.match.url}/pages`}>Pages</Link>
          </div>
          <div className={styles.Section}>
            <Link to={`${this.props.match.url}/questions`}>Questions</Link>
          </div>
          <div className={styles.Section}>
            <Link to={`${this.props.match.url}/settings`}>Settings</Link>
          </div>
          <div className={styles.Section}>
            <Link to={`${this.props.match.url}/submissions`}>Submissions</Link>
          </div>
        </div>
      </div>
    );
  }
}
//returns JS object that we map to property of the state
const mapStateToProps = state => {
  return {
    survey: state.surveyReducer.currentSurvey,
    isFetching: state.surveyReducer.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSurveyIfNeeded: surveyId =>
      dispatch(actions.fetchSurveyIfNeeded(surveyId)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(IndividualSurvey)
);
